
/**
 * Converts number of hours to it's displayable form
 *
 * @param {number} hours Number of hours
 * @returns {string} Number of hours in format '{HOURS}h', where HOURS have 2 digits after a dot
 *
 * @example
 * hoursToString(48) // returns '48.00h'
 *
 * @example
 * hoursToString(undefined) // returns 'h'
 */
// TODO: return '0.00h' when hours undefined or remove this check, when project will be fully typed
export const hoursToString = (hours) => `${hours !== undefined ? hours.toFixed(2) : ''}h`;
