<template>
  <Dialog
    is-persistent
    :width="windowWidth"
    :title="title"
    :text="text"
    :dialog="isOpened"
    :confirmation-button-text="confirmationButtonText"
    :is-confirmation-button-disabled="!isFormValid"
    @confirmationAction="reject"
  >
    <template #content>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-textarea
          v-model="rejectMessage"
          outlined
          counter
          maxlength="512"
          required
          rows="3"
          row-height="35"
          auto-grow
          :rules="[(v) => !!v.trim() || 'Reason is required']"
          class="mt-2"
        >
          <template v-slot:label>
            <div>
              Reason
            </div>
          </template>
        </v-textarea>
      </v-form>
    </template>
  </Dialog>
</template>

<script>

import { mapActions, mapState } from 'vuex';

import { Dialog } from '@/components/shared';

export default {
  components: {
    Dialog,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      title: 'Report rejection',
      rejectMessage: '',
      confirmationButtonText: 'Reject',
      isFormValid: false,
      windowWidth: '700px',
    };
  },
  computed: {
    ...mapState('reports/main', [
      'report',
    ]),
  },
  async created() {
    document.addEventListener('keydown', this.handleKeypress);
    document.addEventListener('keyup', this.handleKeyup);
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeypress);
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    ...mapActions('reports/reportComments', ['createComment']),
    /** @param {boolean} isConfirmed */
    reject(isConfirmed) {
      if (isConfirmed) {
        const data = {
          reportId: this.report.id,
          message: this.rejectMessage,
        };

        this.createComment(data);
      }

      this.rejectMessage = '';
      this.$emit('confirmationAction', isConfirmed);
    },
    closeDialog() {
      this.rejectMessage = '';
      this.$emit('close');
    },
    /** @param {KeyboardEvent} event */
    handleKeypress(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.closeDialog();
      }
    },
    /** @param {KeyboardEvent} event */
    handleKeyup(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault();
      }
    },
  },
};
</script>
