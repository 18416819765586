<template>
  <tr
    v-if="!isEditing"
    :class="{
      'sow-type-table-row': true,
      'clickable': isReportEditable
    }"
    @click="editSummaryReportUnit"
  >
    <template v-for="(column, index) in headers">
      <td
        v-if="column.value !== 'actions' && column.value !== 'comment'"
        :key="index"
        :class="[
          calculateTableClass(column.value, item),
          column.value !== 'employee.name' ? 'text-nowrap' : '',
          highlight && isOverLimit(column.value, item),
        ]"
      >
        {{ getByPath(item, column.value) }}
      </td>
      <td
        v-else-if="column.value === 'comment'"
        :key="index"
        :class="[
          calculateTableClass(column.value, item),
        ]"
      >
        <div class="text-preline">{{ getByPath(item, column.value) }}</div>
      </td>
      <td
        v-else-if="isReportEditable && isProjectManagerOfReport && isReportOfficeMatching"
        :key="index"
        :class="calculateTableClass(column.value, item)"
      >
        <v-row justify="end">
          <v-btn
            text
            icon
            small
            :disabled="isSummaryEditing"
            @click.stop="regenerateRow"
          >
            <v-icon>mdi-file-restore-outline</v-icon>
          </v-btn>

          <v-btn
            text
            icon
            small
            :disabled="isSummaryEditing"
            @click.prevent.stop="deleteRow"
          >
            <v-icon>delete_outline</v-icon>
          </v-btn>
        </v-row>
      </td>
    </template>
  </tr>
  <SummaryReportUnitInput
    v-else
    :key="item.id"
    :summary-report-unit-prototype="item"
    :headers="headers"
    @onUpdate="updateSummaryUnit"
    @onCancel="cancelSummaryReportUnitEditing"
  />
</template>

<script>
import get from 'lodash/get';
import { mapActions, mapGetters } from 'vuex';

import SummaryReportUnitInput from './SummaryReportUnitInput.vue';

export default {
  components: {
    SummaryReportUnitInput,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    reportUnits: {
      type: Array,
      required: true,
    },
    highlight: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters('reports/summary', ['isSummaryEditing']),
    ...mapGetters('reports/main', [
      'isReportEditable',
      'isProjectManagerOfReport',
      'isReportOfficeMatching',
    ]),
  },
  methods: {
    ...mapActions('reports/summary', [
      'setIsSummaryEditing',
      'updateSummaryReportUnit',
      'getSummary',
    ]),
    ...mapActions('reports/units', [
      'getReportUnits',
      'setReportUnits',
    ]),

    setEditing(value) {
      this.isEditing = value;
      this.setIsSummaryEditing(value);
    },

    editSummaryReportUnit() {
      if (!this.isReportEditable || this.isSummaryEditing) return;

      this.setEditing(true);
    },
    updateSummaryUnit(summaryUnit) {
      this.updateSummaryReportUnit(summaryUnit);
      this.setEditing(false);
    },
    cancelSummaryReportUnitEditing() {
      this.setEditing(false);
    },

    regenerateRow() {
      this.$emit('onRegenerate', this.item);
    },

    deleteRow() {
      this.$emit('onDelete', this.item);
    },

    calculateTableClass(column, item) {
      if (column !== 'regular_time') return 'text-start';

      const invoicedTime = item.regular_time + item.non_working_time;

      return invoicedTime < item.expected_time ? ['text-start', 'red', 'lighten-3'] : 'text-start';
    },
    isOverLimit(column, item) {
      if (column !== 'employee.name') {
        return '';
      }

      const findMatchedEmployeeWithItem = this.reportUnits.find((el) => el.employee.id === item.employee.id && el.over_limit);

      if (findMatchedEmployeeWithItem) {
        return 'amber lighten-4';
      }

      return '';
    },
    getByPath(object, path) {
      return get(object, path);
    },
  },
};
</script>
<style lang="less">
.sow-type-table-row {
  .clickable {
    cursor: pointer;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  .text-preline {
    white-space: pre-line;
  }
}
</style>
