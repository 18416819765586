<template>
  <v-card class="attachments-table">
    <v-data-table
      hide-default-footer
      show-select
      fixed-header
      :value="selectedAttachments"
      :headers="headers"
      :items="attachments"
      :items-per-page="-1"
      :dense="denseTables"
      :height="attachmentsTableHeight"
      @input="setSelected"
    >
      <template #header.data-table-select="{ props }">
        <v-checkbox
          v-bind="props"
          color="primary"
          hide-details
          @change="toggleAll"
        />
      </template>

      <template #item.data-table-select="{ isSelected, select }">
        <v-checkbox
          color="primary"
          hide-details
          :input-value="isSelected"
          @change="select"
        />
      </template>

      <template #item.action="{ item }">
        <v-row justify="end">
          <v-btn
            text
            icon
          >
            <v-icon
              @click="downloadSingleAttachment(item)"
            >
              mdi-download-outline
            </v-icon>
          </v-btn>
          <v-btn
            text
            icon
          >
            <v-icon
              @click="openConfirmationDialog(item)"
            >
              delete_outline
            </v-icon>
          </v-btn>
        </v-row>
      </template>
      <template
        v-if="loading"
        #body
      >
        <SkeletonTableRow
          v-for="index in 3"
          :key="index"
          have-checkbox
          :headers="headers"
        />
      </template>
      <template #footer>
        <v-divider />
        <v-row
          justify="end"
          class="mx-0 pa-4"
        >
          <SubmitBtn
            class="mr-3"
            :disabled="!selectedAttachments.length || isAttachChangesDisabled"
            @clickAction="openConfirmationDialog()"
          >
            Delete
          </SubmitBtn>
          <SubmitBtn
            class="mr-3"
            :disabled="!selectedAttachments.length"
            :action-names="[loadingTypes.attachmentsReportButton]"
            @clickAction="downloadAttachments()"
          >
            Download
          </SubmitBtn>
        </v-row>
      </template>
    </v-data-table>

    <DeletionConfirmationDialog
      title="Confirmation"
      confirmation-button-text="Delete"
      :text="confirmationDialogText"
      :dialog="confirmationDialog"
      @confirmationAction="attachmentsDeleteConfirmation"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import {
  Button as SubmitBtn,
  Dialog as DeletionConfirmationDialog,
  SkeletonTableRow,
} from '@/components/shared';
import { loadingTypes } from '@/constants/loadingTypes';
import { attachmentsTableHeight } from '@/constants/tableHeights';
import apiClient from '@/services/api-client';

export default {
  name: 'AttachmentTable',
  components: {
    DeletionConfirmationDialog,
    SubmitBtn,
    SkeletonTableRow,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isAttachChangesDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loadingTypes,
      attachmentsTableHeight,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Format', value: 'type' },
        { text: 'Size', value: 'size' },
        { text: 'Uploaded by', value: 'uploader' },
        { value: 'action', sortable: false },
      ],
      confirmationDialog: false,
      attachmentsToDelete: [],
      selectedAttachments: [],
    };
  },
  computed: {
    ...mapGetters('reports/attachments', ['attachments']),
    ...mapGetters('user/settings', ['denseTables']),
    ...mapGetters('auth/account', ['user', 'userProjects']),
    ...mapGetters('shared/roles', ['projectRolesMap']),

    confirmationDialogText() {
      const { length } = this.attachmentsToDelete;

      return `Are you sure you want to delete ${length > 1 ? `${length} attachments` : 'this attachment'}`;
    },
  },
  methods: {
    ...mapActions('reports/attachments', ['deleteAttachments']),

    setSelected(selectedItems) {
      this.selectedAttachments = selectedItems;
    },
    toggleAll() {
      this.selectedAttachments = this.selectedAttachments.length !== this.attachments.length ? this.attachments : [];
    },

    openConfirmationDialog(attachment) {
      this.attachmentsToDelete = attachment ? [attachment.id] : this.selectedAttachments.map((el) => el.id);
      this.confirmationDialog = true;
    },
    attachmentsDeleteConfirmation(confirmation) {
      if (confirmation) {
        const payload = {
          reportId: this.report.id,
          attachmentsIds: this.attachmentsToDelete,
        };

        this.deleteAttachments(payload);
        this.selectedAttachments = [];
      }

      if (this.attachmentsToDelete.length) {
        this.attachmentsToDelete = [];
      }

      this.confirmationDialog = false;
    },
    async downloadSingleAttachment(attachment) {
      const response = await apiClient.reportApi.downloadSingleAttachment(
        this.report.id,
        attachment.id,
        { loadingType: loadingTypes.attachmentsReportButton },
      );

      this.$saveFile(response);
    },
    async downloadAttachments() {
      const attachmentsToDownload = this.selectedAttachments.map((el) => el.id);

      if (attachmentsToDownload.length === 1) {
        const attachment = this.selectedAttachments.find((attachment) => attachmentsToDownload[0] === attachment.id);

        this.downloadSingleAttachment(attachment);

        return;
      }

      if (attachmentsToDownload.length !== this.attachments.length) {
        const response = await apiClient.reportApi.downloadBulkAttachments(
          this.report.id,
          attachmentsToDownload,
          { loadingType: loadingTypes.attachmentsReportButton },
        );

        this.$saveFiles(response);

        return;
      }

      const response = await apiClient.reportApi.downloadAllAttachments(
        this.report.id,
        { loadingType: loadingTypes.attachmentsReportButton },
      );

      this.$saveFile(response);
    },
  },
};
</script>
<style lang="less">
  .attachments-table .v-input.v-input--selection-controls {
    margin-top: 0;
  }
</style>
