export default [
  'text/plain',
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/pjpeg',
  'image/gif',
  'image/svg+xml',
  'image/bmp',
  'image/x-windows-bmp',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/rtf',
  'application/x-rtf',
  'text/richtext',
  'application/vnd.oasis.opendocument.formula',
  'application/zip',
  'application/x-7z-compressed',
  'application/vnd.rar',
  'application/x-compressed',
  'application/x-gzip',
  'multipart/x-gzip',
  'application/x-tar',
  'application/gnutar',
  'application/x-compressed',
  'application/gzip',
  '',
];
