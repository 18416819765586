<template>
  <div
    class="drop-zone"
    :class="{ 'active': overlay }"
    @dragenter="dragEnterHandler"
    @dragleave.self="overlay = false"
    @dragover.prevent
    @drop.prevent="attachFiles($event.dataTransfer.files)"
  >
    <v-overlay
      :value="overlay"
      class="drop-overlay"
    >
      <v-icon size="50">mdi-paperclip</v-icon>
      Drop files to attach
    </v-overlay>
    <v-col
      id="report-details"
      class="bar-shadow primary-background flex-column-with-tabs"
    >
      <div>
        <v-row class="pa-4">
          <v-col>
            <DetailsHeader
              :loading="!report.id"
              :title="report.project_title"
              :chip-text="report.status"
            >
              <template
                v-if="isAllowedToChangeReportStatus"
                v-slot:status
              >
                <StatusMenu
                  :current-status="report.status"
                  :statuses-select-items="reportStatusesSelectItems"
                  @select="checkToOpenDialog"
                />
              </template>
              <template #subtitle>
                <div class="mr-4">{{ dateRange }}</div>
              </template>
            </DetailsHeader>
          </v-col>
          <v-col
            v-if="isPermissionsLoaded"
            class="report-buttons-aligment col-max-width-fit-content"
          >
            <v-tooltip
              v-if="isProjectManagerOfReport && isReportOfficeMatching"
              bottom
            >
              <template #activator="{ on }">
                <Button
                  id="re-compile-report-button"
                  color="primary"
                  class="ma-1"
                  :on="!largeScreenHelper ? on : null"
                  :action-names="[loadingTypes.recompileReportButton]"
                  :disabled="isRegenerateButtonDisabled"
                  @clickAction="showConfirmationDialog"
                >
                  <v-icon v-if="!largeScreenHelper">mdi-file-restore</v-icon>
                  <span v-else>{{ reCompileUnitsText }}</span>
                </Button>
              </template>
              <span>{{ reCompileUnitsText }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="isFileAttachingAllowed && isReportEditable"
              bottom
            >
              <template #activator="{ on }">
                <Button
                  id="attach-file-button"
                  color="primary"
                  class="ma-1"
                  :on="!largeScreenHelper ? on : null"
                  :action-names="[loadingTypes.attachFileButton]"
                  :disabled="!isReportEditable"
                  @clickAction="showFileDialog"
                >
                  <v-icon v-if="!largeScreenHelper">mdi-paperclip</v-icon>
                  <span v-else>{{ uploadFileText }}</span>
                </Button>
              </template>
              <span>{{ uploadFileText }}</span>
            </v-tooltip>
            <template>
              <v-menu
                transition="scale-transition"
                :offset-y="true"
              >
                <template #activator="{ on: menu }">
                  <v-tooltip
                    bottom
                    :disabled="false"
                  >
                    <template #activator="{ on: tooltip }">
                      <Button
                        id="excel-tools"
                        color="primary"
                        class="turnOrange excel-tools-button ma-1"
                        :action-names="[loadingTypes.reportExportToExcelButton]"
                        :on="!largeScreenHelper ? { ...tooltip, ...menu } : { ...menu }"
                      >
                        <v-icon v-if="!largeScreenHelper">mdi-file-table</v-icon>
                        <span v-else>{{ exportToExcelText }}</span>
                      </Button>
                    </template>
                    <span>{{ exportToExcelText }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in xlsActionItems"
                    :key="index"
                    @click="onXlsItemChoosen(index)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-col>
          <v-col v-else>
            <v-row justify="end">
              <v-skeleton-loader
                class="mr-2"
                type="button"
              />
              <v-skeleton-loader
                class="mr-2"
                type="button"
              />
              <v-skeleton-loader
                class="mr-2"
                type="button"
              />
              <v-skeleton-loader type="button" />
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="isPermissionsLoaded">
          <input
            ref="attachFile"
            type="file"
            multiple
            :accept="allowedAttachmentsTypes"
            style="display: none"
            @change="attachFiles($refs.attachFile.files)"
          >
        </v-row>
      </div>

      <v-row>
        <v-tabs
          v-model="openedTab"
          show-arrows
          color="primary"
          class="tabs-bar-shadow"
        >
          <v-tab>
            Summary
          </v-tab>
          <v-tab-item>
            <v-row>
              <template v-if="isSummaryLoaded">
                <template v-for="(summary, index) in summarySowTypes">
                  <SowTypeTable
                    :key="index"
                    :report-units="reportUnits"
                    :highlight="highlighted"
                    :sow="summary"
                    :invested="summary.sow_type === 'invested'"
                  />
                </template>
              </template>
              <template v-else>
                <SowTypeTableSkeleton :rows="5" />
                <SowTypeTableSkeleton :rows="3" />
              </template>
            </v-row>
          </v-tab-item>

          <v-tab>
            <v-badge
              color="primary"
              inline
              :content="attachments.length"
              :value="attachments.length > 0"
            >
              Attachments
            </v-badge>
          </v-tab>
          <v-tab-item>
            <v-row>
              <AttachmentTable
                :report="report"
                :loading="!isAttachmentsLoaded"
                :is-attach-changes-disabled="!isReportEditable"
              />
            </v-row>
          </v-tab-item>

          <v-tab>
            <v-badge
              color="primary"
              inline
              :content="comments.length"
              :value="comments.length > 0"
            >
              Comments
            </v-badge>
          </v-tab>
          <v-tab-item>
            <v-row>
              <v-col cols="5">
                <ReportComment
                  v-for="comment in comments"
                  :key="comment.comment_id"
                  class="mb-5 ml-0"
                  :name="comment.employee_name"
                  :text="comment.message"
                  :date="comment.created_at"
                />
              </v-col>
            </v-row>
          </v-tab-item>

          <template v-for="(tab, employeeIndex) in employeesTabs">
            <v-tab
              :key="`${tab.assignment_id}_header`"
              :class="{ 'amber lighten-4': highlighted && isOverLimit(tab) }"
            >
              {{ tab.label }}
            </v-tab>
            <v-tab-item :key="`${tab.assignment_id}_content`">
              <v-row>
                <ReportUnitEmployeeTable
                  :highlight="highlighted"
                  :is-current-tab="employeeIndex + 3 === openedTab"
                  :assignment="tab"
                  :loading="!isReportUnitsLoaded"
                  @toggleReportUnitsLoaded="toggleReportUnitsLoaded"
                  @toggleSummaryLoaded="toggleSummaryLoaded"
                />
              </v-row>
            </v-tab-item>
          </template>
        </v-tabs>
      </v-row>
    </v-col>

    <ConfirmationDialog
      :dialog="isConfirmationDialogOpen"
      title="Regenerate report"
      text="Are you sure you want to regenerate report?"
      confirmation-button-text="Regenerate"
      @confirmationAction="reCompileReport"
    />

    <WarningReportDialog
      :is-opened="isWarningDialogOpen"
      :text="warningDialogText"
      :warning-items="reportWarnings"
      @close="isWarningDialogOpen = false"
      @confirmationAction="validateReport"
    />

    <RejectReportDialog
      :is-opened="isRejectDialogOpen"
      :text="rejectDialogText"
      @close="isRejectDialogOpen = false"
      @confirmationAction="rejectReport"
    />

    <ExportToExcelDialog
      :opened="isExcelExportDialogOpen"
      @close="isExcelExportDialogOpen = false"
      @addRaftLogo="addRaftLogoHandler"
    />
  </div>
</template>

<script>

import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import { Button, Dialog as ConfirmationDialog, DetailsHeader, StatusMenu } from '@/components/shared';
import allowedAttachmentsTypes from '@/constants/allowedAttachmentsTypes';
import { billableStatuses } from '@/constants/billableStatuses';
import { loadingTypes } from '@/constants/loadingTypes';
import { resource, allowedToAction } from '@/constants/permissions';
import { reportStatuses } from '@/constants/reportStatuses';
import { systemRolesNames } from '@/constants/roles';
import { DateHelper, DateFormat } from '@/helpers/date.helper';
import { FileSizeConverter } from '@/helpers/file-size-converter';
import { NotificationHelper } from '@/helpers/notification.helper';
import { isLargeScreen } from '@/helpers/screen.helper';
import apiClient from '@/services/api-client';

import ExportToExcelDialog from '../shared/ExportToExcelDialog.vue';

import AttachmentTable from './AttachmentTable.vue';
import RejectReportDialog from './RejectReportDialog.vue';
import ReportComment from './ReportComment.vue';
import ReportUnitEmployeeTable from './ReportUnitEmployeeTable.vue';
import SowTypeTable from './SowTypeTable.vue';
import SowTypeTableSkeleton from './SowTypeTableSkeleton.vue';
import WarningReportDialog from './WarningReportDialog.vue';

/**
 * @typedef {import('@/models/employee.interface').Employee} Employee
 */

/**
 * @typedef {Object} Sow
 * @property {string} [sow_po_number]
 * @property {string} [sow_type]
 */

/**
 * @typedef {Object} TeamMemberExtension
 * @property {string} assignment_id
 * @property {string} billable_status
 * @property {Pick<Employee, 'hour_per_week' | 'id' | 'name'>} employee
 * @property {string} label
 */

/**
 * @typedef {Sow & TeamMemberExtension} TeamMember
 */

/**
 * @typedef {TeamMember & { label: string }} EmployeeTab
 */

/**
 * @typedef {Object} ReportWarning
 * @property {number} emptyTotalHours
 * @property {string} sowKey
 * @property {string} [sowType]
 * @property {number} wrongRegularTime
 * @property {number} [overtime]
 */

/**
 * @typedef {Object} ReportComment
 * @property {string} commentId
 * @property {string} message
 * @property {string} createdDate
 * @property {string} updatedDate
 * @property {string} employeeId
 */

/** @constant {number} */
const MAX_FILE_SIZE = 5;
/** @constant {number} */
const MAX_FILES_SIZE = 10;

export default {
  name: 'ReportDetails',
  components: {
    Button,
    StatusMenu,
    DetailsHeader,
    SowTypeTable,
    SowTypeTableSkeleton,
    ConfirmationDialog,
    RejectReportDialog,
    ReportComment,
    WarningReportDialog,
    AttachmentTable,
    ReportUnitEmployeeTable,
    ExportToExcelDialog,
  },
  data() {
    return {
      exportToExcelText: 'Export',
      // TODO: transfer to vuex
      allowedTrasnitionsTo: {
        [reportStatuses.DRAFT]: false,
        [reportStatuses.READY_FOR_REVIEW]: false,
        [reportStatuses.SENT_TO_INVOICING]: false,
        [reportStatuses.REJECTED]: false,
      },
      reCompileUnitsText: 'Regenerate report',
      isConfirmationDialogOpen: false,
      isRejectDialogOpen: false,
      isWarningDialogOpen: false,
      uploadFileText: 'Upload file',
      openedTab: null,
      allowedAttachmentsTypes: allowedAttachmentsTypes.join(),
      overlay: false,
      isPermissionsLoaded: false,
      isReportUnitsLoaded: false,
      isAttachmentsLoaded: false,
      isSummaryLoaded: false,
      newStatus: '',
      loadingTypes,
      xlsActionItems: [
        { title: 'Export to Excel' },
        { title: 'Export to Invoicing' },
      ],
      highlighted: false,
      isExcelExportDialogOpen: false,
      addRaftLogoValue: true,
    };
  },
  computed: {
    ...mapGetters('reports/main', [
      'report',
      'reportId',
      'isProjectManagerOfReport',
      'isReportOfficeMatching',
      'isReportEditable',
    ]),
    ...mapGetters('auth/account', [
      'user',
      'userProjects',
    ]),
    ...mapGetters('user/settings', [
      'dateFormatSettings',
    ]),
    ...mapGetters('reports/summary', ['summaryUnits']),
    ...mapGetters('shared/roles', ['projectRolesMap']),
    ...mapGetters('reports/units', ['reportUnits']),
    ...mapGetters('reports/attachments', ['attachments']),
    ...mapState('reports/reportComments', ['comments']),

    /** @returns {string[]} */
    reportStatusesSelectItems() {
      const allowedStatuses = Object.values(reportStatuses)
        .filter((value) => this.allowedTrasnitionsTo[value]);

      return allowedStatuses;
    },
    /** @returns {boolean} */
    isAllowedToChangeReportStatus() {
      return this.isReportOfficeMatching && this.reportStatusesSelectItems.length > 0;
    },
    /** @returns {boolean} */
    isRegenerateButtonDisabled() {
      return this.report.status !== 'Draft';
    },
    /** @returns {boolean} */
    isFileAttachingAllowed() {
      return this.isProjectManagerOfReport || this.user.role === systemRolesNames.officeDirector;
    },
    /** @returns {string} */
    dateRange() {
      const start = DateHelper
        .format(this.report.start_date, DateFormat.DATE_WITH_SHORT_MONTH);
      const end = DateHelper
        .format(this.report.end_date, DateFormat.DATE_WITH_SHORT_MONTH);

      return start === end ? `${start}` : `${start} - ${end}`;
    },
    largeScreenHelper() {
      return isLargeScreen(this.$vuetify.breakpoint, false);
    },
    /**
     * Gives list with sow info and employee linked to this contract
     * @returns {TeamMember[]}
     */
    teamMembersList() {
      return this.summaryUnits.map(({
        employee,
        sow_po_number,
        sow_type,
        assignment_id,
        billable_status,
      }) => ({
        employee,
        sow_po_number,
        sow_type,
        assignment_id,
        billable_status,
      }));
    },
    /**
     * Team members list with labels for tabs
     * @returns {EmployeeTab[]}
     */
    employeesTabs() {
      return this.addLabels(this.teamMembersList);
    },
    /**
     * Unique sow types sorted by PO number
     * @returns {Sow[]}
     */
    summarySowTypes() {
      const sowTypes = this.summaryUnits
        .filter((assignment) => assignment.billable_status === billableStatuses.billable)
        .concat({ sow_type: 'invested' })
        .map(({ sow_po_number, sow_type }) => ({ sow_po_number, sow_type }))
        .filter((sowType) => sowType !== null);
      const uniqueSowTypes = uniqWith(sowTypes, isEqual);
      const sortedSowTypes = sortBy(uniqueSowTypes, 'sow_po_number');

      return sortedSowTypes;
    },
    /**
     * Grouped by sow and count warnings for everyone
     * @returns {ReportWarning[]}
    */
    reportWarnings() {
      const billableEmployees = this.summaryUnits
        .filter((item) => item.billable_status === billableStatuses.billable);
      const errorList = groupBy(billableEmployees, 'sow_po_number');

      /** @type {ReportWarning[]} */
      const reportWarnings = Object.entries(errorList)
        .map(([sowKey, sowInfo]) => {
          const warningData = { sowKey };

          const wrongRegularTime = sowInfo
            .filter((item) => item.expected_time > item.regular_time + item.non_working_time).length;
          const emptyTotalHours = sowInfo
            .filter((item) => item.total_hours === 0 && item.ratio === 0).length;
          const overtimeEmployees = sowInfo
            .filter((item) => item.approved_overtime > 0).length;

          /** @type {ReportWarning} */
          const reportWarning = {
            ...warningData,
            wrongRegularTime,
            emptyTotalHours,
            sowType: sowInfo[0].sow_type,
          };

          if (overtimeEmployees > this.attachments.length) {
            reportWarning.overtime = overtimeEmployees;
          }

          return reportWarning;
        })
        .filter((reportWarning) => reportWarning.emptyTotalHours || reportWarning.wrongRegularTime);

      return reportWarnings;
    },
    /**
     * Total number of warnings for report
     * @returns {number}
     */
    warningsCount() {
      return this.reportWarnings
        .reduce((sum, current) => {
          if (!current.investedEmployees) {
            sum += current.wrongRegularTime + current.emptyTotalHours;
          } else {
            sum += current.investedEmployees;
          }

          if (current.overtime) sum += current.overtime;

          return sum;
        }, 0);
    },
    /**
     * Computed text message for dialog
     * @returns {string}
     */
    rejectDialogText() {
      return 'Report is going to be rejected. Could you please specify the reason?';
    },
    /**
     * Computed text message for dialog
     * @returns {string}
     */
    warningDialogText() {
      return `Report contains ${this.warningsCount} ${this.warningsCount > 1 ? 'warnings' : 'warning'}.
       Do you want to proceed anyway?`;
    },
  },
  created() {
    const reportId = this.$route.params.id;

    this.getUserProjects();
    this.getProjectRoles();
    this.getAttachments(reportId).finally(() => (this.isAttachmentsLoaded = true));
    this.getComments(reportId);
    this.fetchReportDetails();
  },
  async beforeRouteLeave(to, from, next) {
    await this.clearReportDetails();
    next();
  },
  mounted() {
    this.isHighlight();
  },
  methods: {
    ...mapActions('reports/main', [
      'clearReportDetails',
      'changeReportStatus',
      'getReportById',
      'deleteReport',
      'createReport',
    ]),
    ...mapActions('reports/attachments', [
      'addAttachments',
      'getAttachments',
    ]),
    ...mapActions('projects/main', [
      'getProjectAssignments',
      'getProjectById',
    ]),
    ...mapActions('reports/units', [
      'getReportUnits',
    ]),
    ...mapActions('reports/reportComments', [
      'getComments',
    ]),
    ...mapActions('reports/summary', ['getSummary']),
    ...mapActions('auth/account', ['getUserProjects']),
    ...mapActions('shared/roles', ['getProjectRoles']),
    ...mapActions('shared/taskTypes', ['getProjectsTaskTypes']),
    ...mapMutations('reports/main', ['setReportId']),
    ...mapMutations('reports/units', ['setReportUnits']),

    /** Show file dialog for uploading */
    showFileDialog() {
      const input = this.$refs.attachFile;

      input.click();
    },
    /** @param {DragEvent} event */
    dragEnterHandler(event) {
      const isDroppingFiles = event.dataTransfer.types.includes('Files');

      if (!isDroppingFiles) return;

      this.overlay = true;
    },
    /**  @param {FileList} files */
    async attachFiles(files) {
      if (this.overlay) {
        this.overlay = false;
      }

      const allFilesSizeMB = [...files].reduce((previousValue, file) => {
        const fileSizeMB = Number(FileSizeConverter.converToMB(file.size).slice(0, -2));

        return previousValue + fileSizeMB;
      }, 0);

      if (allFilesSizeMB >= MAX_FILES_SIZE) {
        this.flashError(`Files with a total size of ${MAX_FILES_SIZE}MB can't be uploaded.`);

        return;
      }

      const allowedToUploadFiles = [...files].filter((file) => {
        const fileSizeMB = parseInt(FileSizeConverter.converToMB(file.size), 10);

        if (fileSizeMB > MAX_FILE_SIZE) {
          const firstSentence = `File ${file.name} with size ${fileSizeMB}MB can't be uploaded.`;
          const secondSentence = `The size should be less than ${MAX_FILE_SIZE}MB`;

          this.flashError(`${firstSentence} ${secondSentence}`);

          return false;
        }

        return true;
      });

      // Resetting input value to let user upload the same file twice
      this.$refs.attachFile.value = '';

      if (allowedToUploadFiles.length && allFilesSizeMB < MAX_FILES_SIZE) {
        await this.addAttachments({
          reportId: this.report.id,
          files: allowedToUploadFiles,
          options: { loadingType: loadingTypes.attachFileButton },
        });
        this.openedTab = 1;
      }
    },
    /** Get report details from endpoint */
    async fetchReportDetails() {
      const reportId = this.$route.params.id;
      const options = { loadingType: loadingTypes.recompileReportButton };

      await this.getReportById({ reportId, options });

      if (!this.report.id) return;

      this.setReportId(this.report.id);
      this.isReportUnitsLoaded = false;
      this.isPermissionsLoaded = false;
      this.isSummaryLoaded = false;

      this.getReportUnits({
        reportId: this.report.id,
        options,
      }).finally(() => (this.isReportUnitsLoaded = true));
      this.setReportStatusPermissions().finally(() => (this.isPermissionsLoaded = true));
      this.getSummary({ reportId: this.report.id }).finally(() => (this.isSummaryLoaded = true));
      this.getProjectAssignments({ projectId: this.report.project_id });
      this.getProjectById({ projectId: this.report.project_id });
      const projectIds = [this.report.project_id];

      this.getProjectsTaskTypes({ projectIds });
    },
    /** Show confirmation dialog for copy regenerating report */
    showConfirmationDialog() {
      this.isConfirmationDialogOpen = true;
    },
    /**
     * Regenerate report
     * @param {boolean} value Confirmation of dialog
     */
    async reCompileReport(value) {
      this.isConfirmationDialogOpen = false;

      if (value) {
        this.setReportUnits([]);
        await apiClient.reportApi.regenerateReport(
          this.$route.params.id,
          { loadingType: loadingTypes.recompileReportButton },
        );

        this.fetchReportDetails();
      }
    },
    /** @param {boolean} data */
    addRaftLogoHandler(data) {
      this.addRaftLogoValue = data;
      this.exportToExcel();
    },
    /** Export report units to excel file */
    async exportToExcel() {
      if (this.isReportUnitsEmpty()) {
        NotificationHelper.showError('No data to export');

        return;
      }

      const response = await apiClient.reportUnitApi
        .exportReportUnitsToExcel(
          this.report.id,
          [],
          this.addRaftLogoValue,
          { loadingType: loadingTypes.reportExportToExcelButton },
        );

      this.$saveFile(response);
    },
    /** Download attachments of report */
    async downloadAttachments() {
      const downloadRequests = this.attachments.map((attachment) => apiClient.reportApi.downloadSingleAttachment(
        this.report.id,
        attachment.id,
        { loadingType: loadingTypes.reportExportToExcelButton },
      ));
      const attachments = await Promise.all(downloadRequests);

      this.$saveFiles(attachments);
    },
    /** Download archive with all data for invoicing */
    async exportToInvoicing() {
      if (this.isReportUnitsEmpty()) {
        NotificationHelper.showError('No data to export');

        return;
      }

      const invoicingReport = await apiClient.reportApi
        .dowonloadReportForInvoicing(
          this.report.id,
          { loadingType: loadingTypes.reportExportToExcelButton },
        );

      this.$saveFile(invoicingReport);
    },
    /**
     * Change status of report
     * @param {string} status
     */
    async changeStatus(status) {
      const options = { loadingType: loadingTypes.invoicingReportButton };

      // TODO: need refactoring - replace `switch` with something more suitable
      switch (status) {
        case 'Ready for Review':
          options.loadingType = loadingTypes.reviewReportButton;
          break;

        case 'Draft':
          options.loadingType = loadingTypes.draftReportButton;
          break;

        default:
          break;
      }

      const reportId = this.report.id;
      const response = await this.changeReportStatus({ status, reportId, options });

      this.flashSuccess('Status updated successfully');
      this.report.status = status;
      this.report.is_report_locked = response.data.is_report_locked;
      this.setReportStatusPermissions();
    },
    /**
     * Check permission for changing status
     * @returns {Promise<boolean>}
     */
    async getStatusPermissions(statusTo) {
      const statusFrom = this.report.status;

      if (statusFrom === statusTo) {
        return false;
      }

      const action = allowedToAction(statusTo);

      const { data } = await apiClient.permissionsApi.allowedTo(
        action,
        resource.reportStatusChange,
        this.report.project_id,
        statusFrom,
        { loadingType: loadingTypes.recompileReportButton },
      );

      return data.allowed;
    },
    /** @param {boolean} value */
    toggleSummaryLoaded(value) {
      this.isSummaryLoaded = value;
    },
    /** @param {boolean} value */
    toggleReportUnitsLoaded(value) {
      this.isReportUnitsLoaded = value;
    },
    /** @param {boolean} isConfirmed */
    validateReport(isConfirmed) {
      if (isConfirmed) {
        this.changeStatus(this.newStatus);
      }

      this.newStatus = '';
      this.isWarningDialogOpen = false;
    },
    /** @param {boolean} isConfirmed */
    rejectReport(isConfirmed) {
      if (isConfirmed) {
        this.changeStatus(this.newStatus);
      }

      this.newStatus = '';
      this.isRejectDialogOpen = false;
    },
    /**
     * @param {string} status
    */
    checkToOpenDialog(status) {
      this.checkToOpenRejectDialog(status);
      this.checkToOpenWarningDialog(status);
    },
    /**
     * Open reject dialog to scpecify the reason of rejection
     * @param {string} status
    */
    checkToOpenRejectDialog(status) {
      const isReadyForReview = this.report.status === reportStatuses.READY_FOR_REVIEW;
      const toRejeted = status === reportStatuses.REJECTED;

      if (isReadyForReview && toRejeted) {
        this.newStatus = status;
        this.isRejectDialogOpen = true;
      }
    },
    /**
     * Open warning dialog if this one has warnings
     * @param {string} status
    */
    checkToOpenWarningDialog(status) {
      const isReadyForReview = this.report.status === reportStatuses.READY_FOR_REVIEW;
      const isSentToInvoicing = this.report.status === reportStatuses.SENT_TO_INVOICING;
      const toDraft = status === reportStatuses.DRAFT;
      const toReadyForReview = status === reportStatuses.READY_FOR_REVIEW;
      const toRejeted = status === reportStatuses.REJECTED;

      if (isReadyForReview && toRejeted) return;

      if ((isReadyForReview && toDraft)
      || (isSentToInvoicing && toReadyForReview)
      || this.warningsCount === 0) {
        this.changeStatus(status);

        return;
      }

      this.newStatus = status;
      this.isWarningDialogOpen = true;
    },
    /** Check and set all permissions for changing status */
    async setReportStatusPermissions() {
      const [
        allowedToDraft,
        allowedToReview,
        allowedToInvoicing,
        allowedToReject,
      ] = await Promise.all([
        this.getStatusPermissions(reportStatuses.DRAFT),
        this.getStatusPermissions(reportStatuses.READY_FOR_REVIEW),
        this.getStatusPermissions(reportStatuses.SENT_TO_INVOICING),
        this.getStatusPermissions(reportStatuses.REJECTED),
      ]);

      this.allowedTrasnitionsTo = {
        [reportStatuses.DRAFT]: allowedToDraft,
        [reportStatuses.READY_FOR_REVIEW]: allowedToReview,
        [reportStatuses.SENT_TO_INVOICING]: allowedToInvoicing,
        [reportStatuses.REJECTED]: allowedToReject,
      };
    },
    /**
     * Add labels with name of employee
     * @param {TeamMember[]} teamMembers
     * @returns {EmployeeTab[]}
     */
    addLabels(teamMembers) {
      const newAssignments = [];

      for (const assignment of teamMembers) {
        const duplicate = newAssignments.find((newAssignment) => newAssignment.employee.id === assignment.employee.id);

        if (duplicate) {
          duplicate.label = `${duplicate.employee.name} (${duplicate.sow_po_number
            || duplicate.billable_status})`;

          newAssignments.push({
            label: `${assignment.employee.name} (${assignment.sow_po_number
              || assignment.billable_status})`,
            ...assignment,
          });
        } else {
          newAssignments.push({
            label: assignment.employee.name,
            ...assignment,
          });
        }
      }

      return sortBy(newAssignments, 'label');
    },
    /** @param {number} index */
    async onXlsItemChoosen(index) {
      switch (index) {
        case 0:
          this.isExcelExportDialogOpen = true;
          break;

        case 1:
          await this.exportToInvoicing();
          break;

        default:
          break;
      }
    },
    /** @returns {boolean} */
    isReportUnitsEmpty() {
      return !this.reportUnits.length;
    },
    isOverLimit(tab) {
      const findMatchedEmployeeWithTabs = this.reportUnits.find((el) => el.employee.id === tab.employee.id && el.over_limit);

      if (findMatchedEmployeeWithTabs) {
        return true;
      }

      return false;
    },
    async isHighlight() {
      try {
        const response = await apiClient.permissionsApi.getAbsenceCalc();

        this.highlighted = response.data.allowed;
      } catch (error) {
        if (error.response && error.response.data.day_limit) {
          const errorMessage = NotificationHelper
            .formatLoggedTimeErrorMessage(error.response.data, this.dateFormatSettings);

          NotificationHelper.showError(errorMessage);

          return;
        }

        throw error;
      }
    },
  },
};
</script>

<style lang="less">
@import "~variables";

#report-details {
  .offices-text {
    align-self: center;
  }

  .drop-zone.active * {
    pointer-events: none;
  }

  .drop-zone.active .v-data-table-header th {
    pointer-events: none;
  }

  .drop-overlay {
    font-size: 50px;
  }

  .report-buttons-aligment {
    display: flex;
    justify-content: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    flex-wrap: wrap;
  }
}
</style>
