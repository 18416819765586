<template>
  <v-card elevation="2">
    <v-card-title>
      <v-row justify="space-between">
        <v-col
          class="text-body-2 black--text"
          cols="auto"
        >
          {{ name }}
        </v-col>
        <v-col
          class="text-body-2 grey--text"
          cols="auto"
        >
          {{ dateTimeFormat(date) }}
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="text-body-2 grey--text text--darken-2">
      {{ text }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

import { DateHelper } from '@/helpers/date.helper';

export default {
  name: 'ReportComment',
  props: {
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('user/settings', ['dateFormatSettings']),
  },
  methods: {
    /**
     * @param {string} dateTime
     * @returns {string}
     */
    dateTimeFormat(dateTime) {
      const localTime = DateHelper.utcToLocal(dateTime);

      return DateHelper.getDateTimeAccordingSettings(localTime, this.dateFormatSettings);
    },
  },
};

</script>
