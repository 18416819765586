<template>
  <tr
    v-click-outside="updateSummaryReportUnit"
    class="summary-report-unit-input"
  >
    <template v-for="(column, index) in headers">
      <td
        v-if="!editableColumns.includes(column.value)"
        :key="index"
      >
        <div
          :class="{
            'pt-6': true,
            'text-nowrap': column.value !== 'employee.name',
          }"
        >
          {{ getByPath(summaryReportUnitPrototype, column.value) }}
        </div>
      </td>
      <td
        v-if="column.value === 'expected_time'"
        :key="index"
      >
        <v-text-field
          ref="summaryReportUnit_expected_time"
          v-model="summaryReportUnit.expected_time"
          :placeholder="summaryReportUnitPrototype.expected_time.toString()"
          :rules="[
            () => Boolean(summaryReportUnit.expected_time) || summaryReportUnit.expected_time === 0 || 'Required',
            () => !isNaN(summaryReportUnit.expected_time) || 'Should be a number',
            () => (summaryReportUnit.expected_time >= 0) || 'Should be not negative number',
          ]"
        />
      </td>
      <td
        v-if="column.value === 'expected_irregular_time'"
        :key="index"
      >
        <v-text-field
          ref="summaryReportUnit_expected_time"
          v-model="summaryReportUnit.expected_irregular_time"
          :placeholder="summaryReportUnitPrototype.expected_irregular_time.toString()"
          :rules="[
            () => Boolean(summaryReportUnit.expected_irregular_time)
              || summaryReportUnit.expected_irregular_time === 0
              || 'Required',
            () => !isNaN(summaryReportUnit.expected_irregular_time) || 'Should be a number',
            () => (summaryReportUnit.expected_irregular_time >= 0) || 'Should be not negative number',
          ]"
        />
      </td>
      <td
        v-if="column.value === 'month_time'"
        :key="index"
      >
        <v-text-field
          ref="summaryReportUnit_expected_time"
          v-model="summaryReportUnit.month_time"
          :placeholder="summaryReportUnitPrototype.month_time.toString()"
          :rules="[
            () => Boolean(summaryReportUnit.month_time) || summaryReportUnit.month_time === 0 || 'Required',
            () => !isNaN(summaryReportUnit.month_time) || 'Should be a number',
            () => (summaryReportUnit.month_time >= 0) || 'Should be not negative number',
          ]"
        />
      </td>
      <td
        v-if="column.value === 'non_working_time'"
        :key="index"
      >
        <v-text-field
          ref="summaryReportUnit_non_working_time"
          v-model="summaryReportUnit.non_working_time"
          :placeholder="summaryReportUnitPrototype.non_working_time.toString()"
          :rules="[
            () => Boolean(summaryReportUnit.non_working_time) || summaryReportUnit.non_working_time === 0 || 'Required',
            () => !isNaN(summaryReportUnit.non_working_time) || 'Should be a number',
            () => (summaryReportUnit.non_working_time >= 0) || 'Should be not negative number',
          ]"
        />
      </td>
      <td
        v-if="column.value === 'comment'"
        :key="index"
      >
        <v-textarea
          ref="summaryReportUnit_comment"
          v-model="summaryReportUnit.comment"
          class="text-field"
          rows="1"
          auto-grow
          :autofocus="Boolean(summaryReportUnit.expected_time) && Boolean(summaryReportUnit.non_working_time)"
          :rules="[]"
        />
      </td>
      <td
        v-if="column.value === 'actions'"
        :key="index"
        class="actions-column"
      >
        <v-row
          justify="end"
          class="pt-6"
        >
          <v-btn
            text
            icon
            small
            @click="updateSummaryReportUnit"
          >
            <v-icon>done</v-icon>
          </v-btn>
          <v-btn
            text
            icon
            small
            @click="cancelEdit"
          >
            <v-icon>clear</v-icon>
          </v-btn>
        </v-row>
      </td>
    </template>
  </tr>
</template>

<script>
import get from 'lodash/get';
import vClickOutside from 'v-click-outside';
import { mapGetters } from 'vuex';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    summaryReportUnitPrototype: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  data() {
    const summaryReportUnit = {
      ...this.summaryReportUnitPrototype,
    };

    return {
      summaryReportUnit,
      editableColumns: [
        'expected_time',
        'expected_irregular_time',
        'month_time',
        'non_working_time',
        'comment',
        'actions',
      ],
    };
  },

  computed: {
    ...mapGetters('reports/summary', ['isSummaryEditing']),

    isSummaryReportUnitValid() {
      const isAllInputsValid = Object.entries(this.$refs)
        .every(([key, refs]) => {
          if (!key.startsWith('summaryReportUnit_')) return true;

          return refs[0].validate(true);
        });

      return isAllInputsValid;
    },
  },

  created() {
    document.addEventListener('keyup', this.updateOnCtrlEnter);
    document.addEventListener('keydown', this.cancelOnEscape);
  },

  methods: {
    updateSummaryReportUnit() {
      if (this.isSummaryReportUnitValid) {
        this.storeSummaryReportUnit();
      }
    },

    storeSummaryReportUnit() {
      const updatedSummaryReportUnit = {
        ...this.summaryReportUnit,
        expected_time: Number(this.summaryReportUnit.expected_time),
        expected_irregular_time: Number(this.summaryReportUnit.expected_irregular_time),
        month_time: Number(this.summaryReportUnit.month_time),
        non_working_time: Number(this.summaryReportUnit.non_working_time),
      };

      if (this.isSummaryReportUnitEqual(this.summaryReportUnitPrototype, updatedSummaryReportUnit)) {
        this.cancelEdit();

        return;
      }

      this.$emit('onUpdate', updatedSummaryReportUnit);
    },

    getByPath(object, path) {
      return get(object, path);
    },

    cancelEdit() {
      this.$emit('onCancel');
    },

    isSummaryReportUnitEqual(firstUnit, secondUnit) {
      let isEqual = true;

      this.editableColumns.forEach((columnName) => {
        if (firstUnit[columnName] !== secondUnit[columnName]) {
          isEqual = false;
        }
      });

      return isEqual;
    },

    updateOnCtrlEnter(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        this.updateSummaryReportUnit();
      }
    },
    cancelOnEscape(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.cancelEdit();
      }
    },
  },
};
</script>

<style lang="less">
@import "~variables";

  .summary-report-unit-input {
    .v-text-field__slot {
      font-size: @table-font-size;
    }

    .v-text-field {
      input {
        font-size: @table-font-size;
      }
    }
  }

  .clickable {
    cursor: pointer;
  }

  .text-nowrap {
    white-space: nowrap;
  }
</style>
