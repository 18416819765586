<template>
  <v-card class="mb-4">
    <div class="d-flex justify-space-between pa-4 pb-0">
      <v-card-title class="small-title">
        <v-skeleton-loader
          type="text"
          width="300px"
        />
      </v-card-title>
      <div class="font-weight-light grey--text d-inline">
        <v-skeleton-loader
          type="text"
          width="120px"
        />
      </div>
    </div>
    <v-data-table
      class="sow-type-table"
      item-key="id"
      color="primary"
      disable-sort
      hide-default-footer
      :headers="headers"
      :dense="denseTables"
    >
      <template #body>
        <SkeletonTableRow
          v-for="index in rows"
          :key="index"
          :headers="headers"
        />
        <tr class="grey lighten-2">
          <th
            v-for="(header, index) in headers"
            :key="index"
          >
            <v-skeleton-loader type="text" />
          </th>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

import { SkeletonTableRow } from '@/components/shared';
import { sowTypeHeaders, defaultType } from '@/constants/sowTypeTableHeaders';

export default {
  components: {
    SkeletonTableRow,
  },
  props: {
    rows: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: sowTypeHeaders[defaultType],
    };
  },
  computed: {
    ...mapGetters('user/settings', ['denseTables']),
  },
};
</script>

<style>
</style>
