<template>
  <Dialog
    is-persistent
    :width="windowWidth"
    :title="title"
    :dialog="isOpened"
    :confirmation-button-text="confirmationButtonText"
    @confirmationAction="confirmationAction"
  >
    <template #content>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-alert
          border="left"
          dense
          elevation="2"
          text
          color="orange"
          type="warning"
          outlined
        >
          {{ text }}
        </v-alert>
        <v-expansion-panels
          v-model="openedPanelsIndexes"
          focusable
          multiple
        >
          <v-expansion-panel
            v-for="(item, index) in warningItems"
            :key="index"
          >
            <v-expansion-panel-header class="font-weight-bold">
              {{ groupTitle(item['sowKey'], item['sowType']) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item v-if="item['wrongRegularTime']">
                <v-list-item-content>
                  <v-list-item-title>{{ totalHoursMessage(item['wrongRegularTime']) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item['emptyTotalHours']">
                <v-list-item-content>
                  <v-list-item-title>{{ emptyInvoiceMessage(item['emptyTotalHours']) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item['overtime']">
                <v-list-item-content>
                  <v-list-item-title>{{ overtimeMessage(item['overtime']) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
    </template>
  </Dialog>
</template>

<script>

import { Dialog } from '@/components/shared';

/**
 * @typedef {Object} ReportWarning
 * @property {number} emptyTotalHours
 * @property {string} sowKey
 * @property {string} [sowType]
 * @property {number} wrongRegularTime
 * @property {number} [overtime]
 */

/**
 * @typedef {Object} ReportInitState
 * @property {string} officeId
 * @property {string} projectId
 * @property {string} title
 * @property {string} message
 */

/** @returns {ReportInitState} */
const getReportItemInitialState = () => ({
  officeId: '',
  projectId: '',
  title: '',
  message: '',
});

export default {
  components: {
    Dialog,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    warningItems: {
      type: Array,
      required: true,
    },
    text: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: 'Changing status confirmation',
      confirmationButtonText: 'Confirm',
      valid: true,
      /** @type {ReportInitState} */
      reportItem: getReportItemInitialState(),
      windowWidth: '700px',
    };
  },
  computed: {
    openedPanelsIndexes() {
      return this.warningItems.map((item, index) => index);
    },
  },
  async created() {
    document.addEventListener('keydown', this.handleKeypress);
    document.addEventListener('keyup', this.handleKeyup);
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeypress);
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    /** @param {boolean} value */
    confirmationAction(value) {
      this.$emit('confirmationAction', value);
    },
    closeDialog() {
      this.$emit('close');
    },
    /**
     * Generate title for each expansion panel
     * @param {string} sowNumber
     * @param {string} sowType
     * @returns {string} Returns title of every expansion panel in format "Sow number, sow type"
     */
    groupTitle(sowNumber, sowType) {
      return `${sowNumber}, ${sowType}`;
    },
    /**
     * Generate warning message for less than expected total hours to invoice
     * @param {number} employeesCount
     * @returns {string}
     */
    totalHoursMessage(employeesCount) {
      return `${employeesCount} ${employeesCount > 1 ? 'employees have' : 'employee has'}
      total hours to invoice less than expected`;
    },
    /**
     * Generate warning message for less than expected total hours to invoice
     * @param {number} employeesCount
     * @returns {string}
     */
    emptyInvoiceMessage(employeesCount) {
      return `${employeesCount} ${employeesCount > 1 ? 'employees have' : 'employee has'}
      0 total hours to invoice or monthly ratio to invoice`;
    },
    /**
     * @param {number} employeesCount
     * @returns {string}
     */
    overtimeMessage(employeesCount) {
      return `${employeesCount} ${employeesCount > 1 ? 'employees have' : 'employee has'}
      overtime to invoice but there is no attached approval`;
    },
    /**
     * @param {number} employeesCount
     * @returns {string}
     */
    investedMessage(employeesCount) {
      return `${employeesCount}
      invested ${employeesCount > 1 ? 'employees are' : 'employee is'} presented in the report`;
    },
    /** @param {KeyboardEvent} event */
    handleKeypress(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.closeDialog();
      }
    },
    /** @param {KeyboardEvent} event */
    handleKeyup(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="less">
  button.v-expansion-panel-header.v-expansion-panel-header--active {
    min-height: 48px;
  }

  .v-expansion-panel-content__wrap {
    padding: 0 24px;
  }

  .mdi-exclamation::before {
    content: "\F02A";
  }
</style>
