<template>
  <Dialog
    is-persistent
    :title="title"
    :dialog="isOpened"
    :confirmation-button-text="confirmationButtonText"
    @confirmationAction="copyReportUnits"
  >
    <template #content>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row class="pb-4">
          <v-col>
            <v-autocomplete
              ref="assignmentId"
              class="secondary-background"
              label="Assignment"
              item-value="id"
              :item-text="getAssignmentText"
              :items="assignments"
              :rules="assignmentRules"
              hide-details
              outlined
              dense
              required
              @input="assignmentSelected"
              @change="setSelectedAssignment"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </Dialog>
</template>

<script>
import sortBy from 'lodash/sortBy';
import { mapState, mapGetters } from 'vuex';

import { Dialog } from '@/components/shared';

export default {
  components: {
    Dialog,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: 'Copy Report units',
      confirmationButtonText: 'Copy',
      valid: true,
      assignmentId: -1,
      assignments: [],
      assignmentRules: [
        (v) => !!v || 'Assignment is required',
      ],
    };
  },
  computed: {
    ...mapGetters('reports/summary', ['summaryUnits']),
    ...mapState('projects/main', ['projectAssignments']),
  },
  created() {
    document.addEventListener('keydown', this.handleKeypress);
    document.addEventListener('keyup', this.handleKeyup);
    const assignmentsIds = this.summaryUnits.map((item) => item.assignment_id);

    const unsortedAssignments = this.projectAssignments
      .filter((assignment) => assignmentsIds.includes(assignment.id));

    this.assignments = sortBy(unsortedAssignments, 'employee.name');
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeypress);
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    copyReportUnits(confirmed) {
      if (!confirmed) {
        this.closeDialog();

        return;
      }

      if (!this.validate()) {
        return;
      }

      try {
        this.$emit('copy', this.assignmentId);
      } finally {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit('close');
      this.reset();
    },
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    handleKeypress(event) {
      if (event.key === 'Escape'
        && this.isOpened) {
        event.preventDefault();
        this.closeDialog();
      }
    },
    handleKeyup(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault();
        this.copyReportUnits(true);
      }
    },
    assignmentSelected() {
      this.$refs.assignmentId.isFocused = false;
    },
    setSelectedAssignment(itemId) {
      this.assignmentId = itemId;
    },
    getAssignmentText(item) {
      const currentReportEmployees = this.summaryUnits.map((item) => item.employee.name);
      const hasDuplicates = currentReportEmployees.length !== new Set(currentReportEmployees).size;

      const assignmentType = hasDuplicates ? `(${item.sow_po_number || item.billable_status})` : '';

      return `${item.employee.name}${assignmentType}`;
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>
