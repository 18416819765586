var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isEditing)?_c('tr',{class:{
    'sow-type-table-row': true,
    'clickable': _vm.isReportEditable
  },on:{"click":_vm.editSummaryReportUnit}},[_vm._l((_vm.headers),function(column,index){return [(column.value !== 'actions' && column.value !== 'comment')?_c('td',{key:index,class:[
        _vm.calculateTableClass(column.value, _vm.item),
        column.value !== 'employee.name' ? 'text-nowrap' : '',
        _vm.highlight && _vm.isOverLimit(column.value, _vm.item),
      ]},[_vm._v(" "+_vm._s(_vm.getByPath(_vm.item, column.value))+" ")]):(column.value === 'comment')?_c('td',{key:index,class:[
        _vm.calculateTableClass(column.value, _vm.item),
      ]},[_c('div',{staticClass:"text-preline"},[_vm._v(_vm._s(_vm.getByPath(_vm.item, column.value)))])]):(_vm.isReportEditable && _vm.isProjectManagerOfReport && _vm.isReportOfficeMatching)?_c('td',{key:index,class:_vm.calculateTableClass(column.value, _vm.item)},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"text":"","icon":"","small":"","disabled":_vm.isSummaryEditing},on:{"click":function($event){$event.stopPropagation();return _vm.regenerateRow.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-file-restore-outline")])],1),_c('v-btn',{attrs:{"text":"","icon":"","small":"","disabled":_vm.isSummaryEditing},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteRow.apply(null, arguments)}}},[_c('v-icon',[_vm._v("delete_outline")])],1)],1)],1):_vm._e()]})],2):_c('SummaryReportUnitInput',{key:_vm.item.id,attrs:{"summary-report-unit-prototype":_vm.item,"headers":_vm.headers},on:{"onUpdate":_vm.updateSummaryUnit,"onCancel":_vm.cancelSummaryReportUnitEditing}})
}
var staticRenderFns = []

export { render, staticRenderFns }