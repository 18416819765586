<template>
  <tr
    id="currentlyEditingReportUnit"
    v-click-outside="clickOutsideHandler"
    class="row-editor"
    active
  >
    <td />
    <td />
    <td>
      <v-select
        ref="reportUnit_title"
        v-model="reportUnit.task_type_id"
        :items="taskTypes"
        item-text="title"
        item-value="id"
        label="Task Type"
        persistent-hint
        :autofocus="!reportUnit.task_type_id"
        :rules="[
          () => !!reportUnit.task_type_id || 'Required',
        ]"
      />
    </td>
    <td>
      <v-textarea
        ref="reportUnit_description"
        v-model="reportUnit.description"
        label="Description"
        class="text-field"
        rows="1"
        :autofocus="!!reportUnit.task_type_id"
        auto-grow
        :rules="[
          () => !!reportUnit.description.trim() || 'Required',
        ]"
      />
    </td>
    <td>
      <v-combobox
        ref="reportUnit_time"
        v-model="reportUnit.time"
        label="Time"
        placeholder="0.00"
        :rules="[
          isValidTimeAndOvertime,
          () => !isNaN(reportUnit.time) || 'Should be a number',
          () => (reportUnit.time >= 0) || 'Should be a not negative number',
        ]"
        :items="timeOptions"
        @blur="roundToNearestQuarter"
      />
    </td>
    <td>
      <v-combobox
        ref="reportUnit_overtime"
        v-model="reportUnit.overtime_time"
        label="Overtime"
        placeholder="0.00"
        :rules="[
          isValidTimeAndOvertime,
          () => !isNaN(reportUnit.overtime_time) || 'Should be a number',
          () => (reportUnit.overtime_time >= 0) || 'Should be a not negative number',
        ]"
        :items="timeOptions"
        @blur="roundToNearestQuarter"
      />
    </td>
    <template v-if="!isDefaultReport">
      <td>
        <v-checkbox
          v-model="reportUnit.is_overtime_payable"
          color="primary"
          disabled
          class="checkbox-alignment"
        />
      </td>
      <td>
        <v-text-field
          v-model="reportUnit.overtime_multiplier"
          color="primary"
          disabled
        />
      </td>
      <td>
        <v-checkbox
          v-model="reportUnit.is_billable"
          color="primary"
          disabled
          class="checkbox-alignment"
        />
      </td>
    </template>
    <td>
      <v-menu
        v-model="datePickerVisible"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
      >
        <template #activator="{ on }">
          <v-text-field
            ref="reportUnit_date"
            v-model="reportUnit.logged_date"
            label="Date"
            append-icon="event"
            :rules="[
              () => !!reportUnit.logged_date || 'Required',
              dateIsValid,
              isDateInReportDateRange,
            ]"
            v-on="on"
            @click:append="datePickerVisible = true"
            @keyup.enter="datePickerVisible = true"
          />
        </template>
        <v-date-picker
          ref="datePicker"
          :value="datePickerValue"
          :first-day-of-week="firstDayOfWeek"
          color="primary"
          no-title
          @input="saveDateInUTC"
        />
      </v-menu>
    </td>
    <td class="action-column">
      <v-row
        justify="end"
        class="pt-4"
      >
        <v-btn
          text
          icon
          small
          @click="saveUnit"
        >
          <v-icon>done</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          small
          @click="cancelEdit"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </v-row>
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import vClickOutside from 'v-click-outside';
import { mapActions, mapGetters } from 'vuex';

import { DateHelper, DateFormat, LocaleFormat } from '@/helpers/date.helper';
import { NotificationHelper } from '@/helpers/notification.helper';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    reportUnitPrototype: {
      type: Object,
      required: true,
    },
  },

  data() {
    const reportUnit = {
      ...this.reportUnitPrototype,
      time: this.convertToDisplayTime(this.reportUnitPrototype.minutes),
      overtime_time: this.convertToDisplayTime(this.reportUnitPrototype.overtime_minutes),
    };

    return {
      reportUnit,
      datePickerVisible: false,
      timer: null,
    };
  },

  computed: {
    ...mapGetters('shared/taskTypes', ['projectsTaskTypes']),
    ...mapGetters('user/settings', ['dateFormatSettings']),
    ...mapGetters('reports/main', ['report', 'isDefaultReport']),
    ...mapGetters('reports/units', ['reportUnits']),

    taskTypes() {
      return this.projectsTaskTypes[this.report.project_id] || [];
    },

    reportUnitDateInIso() {
      const dateFormat = this.dateFormatSettings === LocaleFormat.EU ? DateFormat.EU_DATE_FORMAT : DateFormat.VISUAL;

      return DateHelper.toIso(moment(this.reportUnit.logged_date, dateFormat));
    },
    datePickerValue() {
      if (this.reportUnitDateInIso === 'Invalid date') return DateHelper.toIso(moment());

      return this.reportUnitDateInIso;
    },
    firstDayOfWeek() {
      return DateHelper.getFirstDayOfWeekAccordingSettings(this.dateFormatSettings);
    },
    dateIsValid() {
      const validateFormat = this.dateFormatSettings === LocaleFormat.EU
        ? DateFormat.EU_DATE_FORMAT
        : DateFormat.VISUAL;
      const isDateValid = DateHelper.isValid(this.reportUnit.logged_date, validateFormat);

      return !this.reportUnit.logged_date || isDateValid || `Should be: ${validateFormat}`;
    },
    isDateInReportDateRange() {
      const startDateInIso = DateHelper.toIso(this.report.start_date);
      const endDateInIso = DateHelper.toIso(this.report.end_date);

      const isDateInReportInterval = moment(this.reportUnitDateInIso)
        .isBetween(startDateInIso, endDateInIso, undefined, []);

      return isDateInReportInterval
        || `Should be in interval between: ${this.report.start_date} - ${this.report.end_date}`;
    },
    isEdit() {
      return !!this.reportUnitPrototype.id;
    },
    timeOptions() {
      const timeArray = new Array(this.maxEffort + 1)
        .fill(0)
        .map((_, index) => (index).toFixed(2));

      return timeArray;
    },
    maxEffort() {
      return this.reportUnit.employee ? (this.reportUnit.employee.hour_per_week || 40) : 0;
    },
    isValidTimeAndOvertime() {
      return (this.reportUnit.time > 0 || this.reportUnit.overtime_time > 0) || 'Time or overtime should be not 0.00';
    },
  },

  watch: {
    datePickerVisible() {
      // TODO: investigate is it possible to get rid of setTimeouts
      if (this.datePickerVisible) {
        setTimeout(() => this.$refs.datePicker.$el.querySelector('.v-btn').focus(), 100);

        return;
      }

      setTimeout(() => { this.$refs.reportUnit_date.focus(); }, 100);
    },
  },

  mounted() {
    this.scrollToNewReportUnit();
  },

  created() {
    document.addEventListener('keyup', this.handleKeyup);
    document.addEventListener('keydown', this.handleKeypress);

    this.reportUnit.logged_date = this.convertToLocaleFormat(this.reportUnitPrototype.logged_date);
  },

  destroyed() {
    document.removeEventListener('keyup', this.handleKeyup);
    document.removeEventListener('keydown', this.handleKeypress);
  },

  methods: {
    ...mapActions('reports/units', [
      'createReportUnit',
      'updateReportUnit',
      'saveReportUnit',
      'regenerateAssignment',
    ]),

    cancelEdit() {
      this.$emit('cancel');
    },

    scrollToNewReportUnit() {
      document.getElementById('currentlyEditingReportUnit').scrollIntoView({ behavior: 'smooth', block: 'center' });
    },

    clickOutsideHandler(event) {
      const targetClasses = event.target.classList;
      // toString().includes uses because v-list-item isn't class it's substring
      const isTargetListItem = targetClasses.toString().includes('v-list-item');

      if (isTargetListItem || this.datePickerVisible) return;

      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveUnit();
      }, 200);
    },

    saveUnit() {
      if (!this.isReportUnitValid()) return;

      // Manually entered time or overtime values are set when blur.
      // setTimeout is needed so that the component has time to respond
      // to blur in order to send the updated time value for saving by click check mark.
      setTimeout(() => this.storeReportUnit(), 50);
    },
    async storeReportUnit() {
      const saveReportUnit = this.isEdit ? this.updateReportUnit : this.createReportUnit;

      const editedReportUnit = {
        ...this.reportUnit,
        id: this.isEdit ? this.reportUnit.id : null,
        minutes: this.reportUnit.time * 60,
        overtime_minutes: this.reportUnit.overtime_time * 60,
        logged_date: this.reportUnitDateInIso,
        employee_id: this.reportUnit.employee.id,
        task_type_title: this.taskTypes.find((item) => item.id === this.reportUnit.task_type_id).title,
        overtime_multiplier: this.reportUnit.overtime_multiplier || 0,
        is_overtime_payable: this.reportUnit.is_overtime_payable || false,
        is_billable: this.reportUnit.is_billable || false,
        previous_report_unit_id: this.getPrevReportUnitIdForCurrentUnit(),
      };

      const reportUnitNotChanged = this.isEdit && this.isReportUnitsEquals(this.reportUnitPrototype, editedReportUnit);

      if (reportUnitNotChanged) {
        this.cancelEdit();

        return;
      }

      const config = {
        newReportUnit: editedReportUnit,
        oldReportUnit: this.reportUnitPrototype,
      };

      try {
        this.$emit('save');
        await saveReportUnit(config);
      } catch (error) {
        if (error.response && error.response.data.day_limit) {
          const errorMessage = NotificationHelper
            .formatLoggedTimeErrorMessage(error.response.data, this.dateFormatSettings);

          NotificationHelper.showError(errorMessage);

          return;
        }

        throw error;
      }
    },

    roundToNearestQuarter() {
      const { time } = this.reportUnit;

      if (!time || isNaN(time)) return;

      const rounded = (Math.round(time * 4) / 4).toFixed(2);

      this.reportUnit.time = rounded;
    },

    convertToDisplayTime(minutes) {
      return `${(minutes / 60).toFixed(2)}`;
    },

    saveDateInUTC(localDate) {
      event.stopPropagation();

      this.reportUnit.logged_date = this.convertToLocaleFormat(localDate);
      this.datePickerVisible = false;
    },

    convertToLocaleFormat(date) {
      return DateHelper.formatDateAccordingSettings(date, this.dateFormatSettings);
    },
    isReportUnitsEquals(originalReportUnit, editedReportUnit) {
      const isEquel = originalReportUnit.task_type_id === editedReportUnit.task_type_id
        && originalReportUnit.description === editedReportUnit.description
        && originalReportUnit.logged_date === editedReportUnit.logged_date
        && originalReportUnit.overtime_time === editedReportUnit.overtime_time
        && originalReportUnit.time === editedReportUnit.time;

      return isEquel;
    },
    isReportUnitValid() {
      const isAllInputsValid = Object.entries(this.$refs)
        .every(([key, ref]) => {
          if (!key.startsWith('reportUnit_')) return true;

          return ref.validate(true);
        });

      return isAllInputsValid;
    },

    handleKeyup(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        this.saveUnit();
      }
    },

    handleKeypress(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.cancelEdit();
      }
    },

    getPrevReportUnitIdForCurrentUnit() {
      if (this.reportUnitDateInIso !== this.reportUnitPrototype.logged_date) {
        return null;
      }

      const itemIndex = this.reportUnits.findIndex((unit) => unit.id === this.reportUnitPrototype.id);

      if (itemIndex < 1) return null;

      const isSameId = this.reportUnits[itemIndex - 1].employee.id === this.reportUnits[itemIndex].employee.id;
      const isSameDate = this.reportUnits[itemIndex - 1].logged_date === this.reportUnitDateInIso;

      const prevReportUnitId = isSameId && isSameDate ? this.reportUnits[itemIndex - 1].id : null;

      return prevReportUnitId;
    },
  },
};
</script>

<style lang="less">
@import "~variables";

  .row-editor {
    .v-label {
      font-size: @table-font-size;
    }

    .v-text-field__slot {
      font-size: @table-font-size;
    }

    .clipped div.v-select__selection {
      overflow: hidden;
      white-space: nowrap;
    }

    .v-text-field {
      input {
        font-size: @table-font-size;
      }
    }

    // Validation error message
    .v-input {
      .v-text-field__details {
        overflow: visible;
      }

      .v-messages__message {
        position: absolute;
        background: @soft-peach;
        border: 1px solid @pink;
        border-radius: 0 2px 2px 2px;
        width: max-content;
        max-width: 120px;
        padding: 3px;
        color: @red-dark;
        box-shadow: 0 -1px 8px 2px @pink-light;

        &::before {
          content: '';
          position: absolute;
          border: solid transparent;
          border-width: 0 6px 5px 0;
          border-bottom-color: @pink;
          top: -5px;
          left: -1px;
        }
      }
    }
  }

  .checkbox-alignment {
    .v-input--selection-controls__input {
      margin-top: 16px;
    }
  }
</style>
