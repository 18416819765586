<template>
  <v-card>
    <v-card-title>
      <div class="ml-3">{{ tableTitle }}</div>
      <v-row justify="end">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              class="ma-1"
              v-on="on"
            >
              help_outline
            </v-icon>
          </template>
          <UnitShortcuts :unit-subject="'report unit'" />
        </v-tooltip>
        <v-tooltip
          v-if="isProjectManagerOfReport && isReportOfficeMatching"
          bottom
        >
          <template #activator="{ on }">
            <SubmitBtn
              id="report-tab-unit"
              class="ma-1"
              color="primary"
              :on="!largeScreenHelper ? on : null"
              :action-names="[loadingTypes.regener]"
              :disabled="isRegenerateButtonDisabled"
              @clickAction="showRegenerateConfirmationDialog"
            >
              <v-icon v-if="!largeScreenHelper">mdi-file-restore</v-icon>
              <span v-else>{{ addTabText }}</span>
            </SubmitBtn>
          </template>
          <span>{{ addTabText }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <SubmitBtn
              id="add-report-unit"
              class="ma-1"
              color="primary"
              :on="!largeScreenHelper ? on : null"
              :disabled="!isReportEditable || isReportUnitEditing"
              @clickAction="onAddReportUnitAction(employeeUnits.length ? employeeUnits[employeeUnits.length - 1] : null)"
            >
              <v-icon v-if="!largeScreenHelper">add</v-icon>
              <span v-else>{{ addReportUnitText }}</span>
            </SubmitBtn>
          </template>
          <span>{{ addReportUnitText }}</span>
        </v-tooltip>
      </v-row>
    </v-card-title>
    <v-data-table
      class="report-unit-employee-table align-top"
      item-key="id"
      disable-pagination
      disable-sort
      fixed-header
      show-select
      hide-default-footer
      color="primary"
      :dense="denseTables"
      :headers="currentTableHeaders"
      :height="assignmentsTableHeight"
      :items="employeeUnits"
      :value="selectedReportUnits"
      @input="setSelectedReportUnits"
    >
      <template #header.data-table-select="{ props }">
        <v-checkbox
          v-bind="props"
          color="primary"
          hide-details
          class="ml-2"
          :disabled="!isReportEditable || isReportUnitEditing"
          @change="toggleAll"
        />
      </template>
      <template #item="{ isSelected, item, index }">
        <ReportUnitEmployeeTableRow
          :key="item.id"
          :highlight="highlight"
          :index="index"
          :item="item"
          :selected="isSelected"
          :is-current-tab="isCurrentTab"
          @onDelete="askReportUnitDeleteConfirmation"
          @onDuplicate="onDuplicateUnitAction"
          @onSelect="selectItem"
          @onAdd="onAddReportUnitAction"
        />
      </template>
      <template
        v-if="loading"
        #body
      >
        <SkeletonTableRow
          v-for="index in 6"
          :key="index"
          :headers="currentTableHeaders"
        />
      </template>
      <template #footer>
        <v-divider />
        <v-row class="table-footer">
          <div class="footer-text">
            <ProgressActivate
              v-slot="{ isLoading }"
              :action-names="[loadingTypes.reportSchedule]"
            >
              <template v-if="isLoading">
                <v-skeleton-loader
                  type="text"
                  width="300px"
                />
              </template>
              <template v-else>
                Total for the period:
                <template v-if="!scheduledHours">
                  loading...
                </template>
                <template v-else>
                  {{ actualHours }} out of {{ scheduledHours }}
                  <template v-if="overtime">; Overtime: {{ overtime }}</template>
                  <template v-if="(actual>scheduled)">;<span class="red--text"> potential overtime: {{ potentialOvertime }}</span></template>
                </template>
              </template>
            </ProgressActivate>
          </div>
          <v-row
            justify="end"
            class="mx-0"
          >
            <div
              class="status-buttons d-flex flex-wrap"
            >
              <SubmitBtn
                class="ma-1"
                :disabled="isReportUnitEditing || !isReportEditable || isNoReportUnitSelected"
                @clickAction="isDialogOpened = true"
              >
                Copy
              </SubmitBtn>
              <SubmitBtn
                class="ma-1"
                :disabled="isReportUnitEditing || !isReportEditable || isNoReportUnitSelected"
                @clickAction="askReportUnitDeleteConfirmation"
              >
                Delete
              </SubmitBtn>
            </div>
          </v-row>
        </v-row>
      </template>
    </v-data-table>

    <DeletionConfirmationDialog
      :dialog="confirmationDialog"
      :text="confirmationDialogText"
      title="Confirmation"
      confirmation-button-text="Delete"
      @confirmationAction="reportUnitDeleteConfirmation"
    />

    <CopyReportUnitsDialog
      :is-opened="isDialogOpened"
      @close="isDialogOpened = false"
      @copy="copyUnitsToAssignment"
    />

    <RegenerateConfirmationDialog
      :dialog="isRegenerateConfirmationDialogOpen"
      title="Regenerate Emloyee Records"
      confirmation-button-text="Regenerate"
      :text="'Are you sure you want to regenerate ' + this.employeeNameToRegenerate + ' records?'"
      @confirmationAction="regenerateReportUnit"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import {
  Button as SubmitBtn,
  Dialog as DeletionConfirmationDialog,
  SkeletonTableRow,
  ProgressActivate,
  UnitShortcuts,
  Dialog as RegenerateConfirmationDialog,
} from '@/components/shared';
import { loadingTypes } from '@/constants/loadingTypes';
import { assignmentsTableHeight } from '@/constants/tableHeights';
import { hoursToString } from '@/helpers/hours-to-string';
import { isLargeScreen } from '@/helpers/screen.helper';
import apiClient from '@/services/api-client';

import CopyReportUnitsDialog from './CopyReportUnitsDialog.vue';
import ReportUnitEmployeeTableRow from './ReportUnitEmployeeTableRow.vue';

export default {
  name: 'ReportUnitEmployeeTable',
  components: {
    DeletionConfirmationDialog,
    ReportUnitEmployeeTableRow,
    ProgressActivate,
    SkeletonTableRow,
    SubmitBtn,
    CopyReportUnitsDialog,
    UnitShortcuts,
    RegenerateConfirmationDialog,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCurrentTab: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      addTabText: 'Regenerate Tab',
      addReportUnitText: 'Add Record',
      loadingTypes,
      assignmentsTableHeight,
      confirmationDialog: false,
      itemToDelete: '',
      selectedReportUnits: [],
      isDialogOpened: false,
      singleSelect: false,
      isRegenerateConfirmationDialogOpen: false,
      isPermissionsLoaded: false,
      isReportUnitsLoaded: false,
      isAttachmentsLoaded: false,
      isSummaryLoaded: false,
      employeeNameToRegenerate: {},
    };
  },

  computed: {
    ...mapGetters('auth/account', ['user']),
    ...mapGetters('reports/units', [
      'reportUnitsMap',
      'isReportUnitEditing',
    ]),
    ...mapGetters('reports/main', [
      'currentTableHeaders',
      'isReportEditable',
      'report',
      'isProjectManagerOfReport',
      'isReportOfficeMatching',
    ]),
    ...mapGetters('reports/summary', ['summaryUnits']),
    ...mapGetters('user/settings', ['denseTables']),

    largeScreenHelper() {
      return isLargeScreen(this.$vuetify.breakpoint, false);
    },

    /** @returns {boolean} */
    isRegenerateButtonDisabled() {
      return this.report.status !== 'Draft';
    },

    /** @returns {boolean} */
    isNoReportUnitSelected() {
      return this.selectedReportUnits.length === 0;
    },
    assignmentSummary() {
      return this.summaryUnits.find((summaryUnit) => summaryUnit.assignment_id === this.assignment.assignment_id);
    },
    tableTitle() {
      if (!this.assignment.sow_po_number && !this.assignment.sow_type) {
        return this.assignment.billable_status;
      }

      return `${this.assignment.sow_po_number}, ${this.assignment.sow_type}`;
    },
    employeeUnits() {
      const employeeUnits = this.reportUnitsMap[this.assignment.assignment_id] || [];

      return this.addEvenHighlight(employeeUnits);
    },
    actual() {
      const actualMinutes = this.getNonOvertimeMinutes();

      return actualMinutes / 60;
    },
    actualHours() {
      const actualMinutes = this.getNonOvertimeMinutes() + this.getOvertimeMinutes();

      return hoursToString(actualMinutes / 60);
    },
    scheduled() {
      return this.assignmentSummary.expected_time;
    },
    scheduledHours() {
      return hoursToString(this.scheduled);
    },
    overtime() {
      const overtimeMinutes = this.getOvertimeMinutes();

      return overtimeMinutes === 0 ? null : hoursToString(overtimeMinutes / 60);
    },
    potentialOvertime() {
      return hoursToString(this.actual - this.scheduled);
    },
    total() {
      const defaultTotal = {
        minutes: 0,
        overtime: 0,
      };

      /* eslint-disable no-param-reassign */
      const total = this.employeeUnits.reduce((total, { overtime, minutes }) => {
        if (overtime) {
          total.overtime += minutes;
        } else {
          total.minutes += minutes;
        }

        return total;
      }, defaultTotal);
      /* eslint-enable */

      return total;
    },
    confirmationDialogText() {
      return this.selectedReportUnits.length > 1 && !this.itemToDelete
        ? `Are you sure you want to delete ${this.selectedReportUnits.length} report units?`
        : 'Are you sure you want to delete this report unit?';
    },
  },
  watch: {
    reportUnitsMap() {
      const reportUnitsIds = this.employeeUnits.map((item) => item.id);

      this.selectedReportUnits = this.selectedReportUnits.filter((report) => reportUnitsIds.includes(report.id));
    },
  },
  created() {
    document.addEventListener('keyup', this.handleKeyup);
    this.setReportUnitEditing(false);
    this.fetchReportDetails();
  },
  destroyed() {
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    ...mapActions('reports/units', [
      'deleteReportUnit',
      'addNewReportUnit',
      'duplicateReportUnit',
      'copyReportUnits',
      'deleteBatchReportUnits',
      'resetModuleState',
      'getReportUnits',
    ]),
    ...mapActions('projects/main', [
      'getProjectAssignments',
      'getProjectById',
    ]),
    ...mapActions('reports/main', [
      'getReportById',
      'getReportForAssignment',
    ]),
    ...mapActions('shared/taskTypes', ['getProjectsTaskTypes']),
    ...mapActions('reports/summary', ['getSummary']),
    ...mapMutations('reports/units', ['setReportUnitEditing']),
    ...mapMutations('reports/main', ['setReportId']),
    ...mapMutations('reports/units', ['setReportUnits']),

    /** Get report details from endpoint */
    async fetchReportDetails() {
      const options = { loadingType: loadingTypes.regener };

      this.$emit('toggleReportUnitsLoaded', false);
      this.$emit('toggleSummaryLoaded', false);
      this.getReportUnits({
        reportId: this.report.id,
        options,
      }).finally(() => (this.$emit('toggleReportUnitsLoaded', true)));
      this.getSummary({ reportId: this.report.id }).finally(() => (this.$emit('toggleSummaryLoaded', true)));
    },

    /** Show confirmation dialog for copy regenerating report */
    showRegenerateConfirmationDialog() {
      this.employeeNameToRegenerate = this.assignment.label;
      this.isRegenerateConfirmationDialogOpen = true;
    },
    /**
     * Regenerate tab (report)
     * @param {boolean} value Confirmation of dialog
     */
    async regenerateReportUnit(value) {
      this.isRegenerateConfirmationDialogOpen = false;

      if (value) {
        this.setReportUnits([]);
        await apiClient.reportUnitApi.regenerateAssignment(
          this.$route.params.id,
          this.assignment.assignment_id,
          { loadingType: loadingTypes.regener },
        );

        this.fetchReportDetails();
      }
    },
    onAddReportUnitAction(item) {
      this.addNewReportUnit({ originReportUnit: item, currentAssignment: this.assignment });
    },

    onDuplicateUnitAction(item) {
      this.duplicateReportUnit(item);
    },
    addEvenHighlight(units) {
      let isEven = true;

      return units
        .map((unit, index, units) => {
          const previousUnitDate = index > 0 ? units[index - 1].logged_date : unit.logged_date;

          if (previousUnitDate !== unit.logged_date) {
            isEven = !isEven;
          }

          return {
            ...unit,
            isEven,
          };
        });
    },
    selectItem(item) {
      const foundIndex = this.selectedReportUnits.findIndex((v) => v.id === item.id);

      if (foundIndex !== -1) {
        this.selectedReportUnits.splice(foundIndex, 1);
      } else {
        this.selectedReportUnits.push(item);
      }
    },
    askReportUnitDeleteConfirmation(item) {
      if (item || this.selectedReportUnits.length === 1) {
        this.itemToDelete = item || this.selectedReportUnits[0];
      } else {
        this.itemToDelete = '';
      }

      this.confirmationDialog = true;
    },
    async reportUnitDeleteConfirmation(isConfirmed) {
      const isSelectedReportUnits = this.selectedReportUnits.length > 1 && !this.itemToDelete;

      let deleteFunction;
      let itemsToDelete;

      if (isSelectedReportUnits) {
        deleteFunction = this.deleteBatchReportUnits;
        itemsToDelete = {
          reportUnitsToDelete: this.selectedReportUnits,
          assignmentId: this.assignment.assignment_id,
          reportId: this.report.id,
        };
      } else {
        deleteFunction = this.deleteReportUnit;
        itemsToDelete = { reportUnitId: this.itemToDelete.id };
      }

      this.confirmationDialog = false;

      if (isConfirmed) {
        this.$emit('toggleSummaryLoaded', false);
        await deleteFunction(itemsToDelete);
        this.$emit('toggleSummaryLoaded', true);
      }
    },
    setSelectedReportUnits(selectedReportUnits) {
      this.selectedReportUnits = selectedReportUnits;
    },
    getNonOvertimeMinutes() {
      const minutes = this.employeeUnits
        .filter((unit) => unit.id !== null)
        .reduce((sum, reportUnit) => sum + reportUnit.minutes, 0);

      return minutes;
    },
    getOvertimeMinutes() {
      return this.employeeUnits.reduce((sum, reportUnit) => sum + reportUnit.overtime_minutes, 0);
    },
    async copyUnitsToAssignment(assignmentId) {
      await this.copyReportUnits({ reportUnits: this.selectedReportUnits, assignmentId });

      this.cleanSelection();
      this.$emit('updated');
    },
    toggleAll() {
      this.selectedReportUnits = this.selectedReportUnits.length === this.employeeUnits.length
        ? []
        : this.employeeUnits;
    },
    cleanSelection() {
      this.selectedReportUnits = [];
    },
    handleKeyup(event) {
      if (this.isReportUnitEditing
        || !this.isReportEditable
        || !this.isCurrentTab) return;

      if (
        (event.key === 'Insert' && event.ctrlKey)
        || (event.key === 'Enter' && event.shiftKey)
      ) {
        const originReportUnit = this.employeeUnits.length
          ? this.employeeUnits[this.employeeUnits.length - 1]
          : null;

        this.addNewReportUnit({ originReportUnit,
          currentAssignment: this.assignment });
      }
    },
  },
};
</script>

<style lang="less">
  .report-unit-employee-table__header {
    &-type {
      min-width: 165px;
      width: 165px;
    }
    &-time {
      min-width: 135px;
      width: 135px;
    }
    &-overtime {
      min-width: 135px;
      width: 135px;
    }
    &-overtime-payable {
      min-width: 50px;
      width: 50px;
    }
    &-overtime-multiplier {
      min-width: 50px;
      width: 50px;
    }
    &-billable {
      min-width: 50px;
      width: 50px;
    }
    &-date {
      min-width: 150px;
      width: 150px;
    }
  }

  .report-unit-employee-table {
    overflow: auto;

    .v-input.v-input--selection-controls {
      margin-top: 0;
    }

    th.add-button-column {
      background-color: white;
      position: sticky;
      z-index: 2;
    }

    .add-button-column {
      padding: 0px;
      position: relative;
    }

    tbody::after {
      content: '';
      display: block;
      height: 24px;
    }

    .status-buttons {
      position: relative;

      &:before {
        position: absolute;
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        left: -8px;
        top: -8px;
        border-radius: 4px;
        background: #f26939;
        opacity: 0;
        content: '';
      }

      &--emphasised:before {
        opacity: 0.25;
      }
    }
  }
</style>
