<template>
  <v-card
    v-if="summaryData && summaryData.length"
    class="mb-4"
  >
    <div class="d-flex justify-space-between pa-4 pb-0">
      <v-card-title class="small-title">{{ title }}</v-card-title>
      <div class="font-weight-light grey--text">Total records: {{ summaryData.length }}</div>
    </div>
    <v-data-table
      class="sow-type-table align-top"
      item-key="id"
      color="primary"
      fixed-header
      disable-pagination
      hide-default-footer
      :items-per-page="5"
      :headers="currentSowTypeHeaders"
      :items="summaryData"
      :dense="denseTables"
    >
      <template #item="{ item, headers }">
        <SowTypeTableRow
          :report-units="reportUnits"
          :highlight="highlight"
          :item="item"
          :headers="headers"
          @onRegenerate="showRegenerateConfirmationDialog"
          @onDelete="openConfirmationDialog"
        />
      </template>

      <template #body.append="{ headers }">
        <tr class="total-background">
          <th
            v-for="(header, index) in headers"
            :key="index"
          >
            {{ index === 0 ? 'Total:' : total[header.value] }}
          </th>
        </tr>
      </template>
    </v-data-table>

    <DeletionConfirmationDialog
      title="Confirmation"
      confirmation-button-text="Delete"
      :text="deletionConfirmationText"
      :dialog="isConfirmationDialogOpened"
      @confirmationAction="deleteUnit"
    />

    <RegenerateConfirmationDialog
      title="Regenerate Emloyee Records"
      confirmation-button-text="Regenerate"
      :text="'Are you sure you want to regenerate ' + this.employeeNameToRegenerate + ' records?'"
      :dialog="isRegenerateConfirmationDialogOpen"
      @confirmationAction="regenerateAssignment"
    />
  </v-card>
</template>

<script>
import sortBy from 'lodash/sortBy';
import { mapActions, mapGetters } from 'vuex';

import {
  Dialog as DeletionConfirmationDialog,
  Dialog as RegenerateConfirmationDialog,
} from '@/components/shared';
import { loadingTypes } from '@/constants/loadingTypes';
import sowTypeIDs from '@/constants/sowTypeIDs';
import { sowTypeHeaders, defaultType } from '@/constants/sowTypeTableHeaders';
import apiClient from '@/services/api-client';

import SowTypeTableRow from './SowTypeTableRow.vue';

export default {
  name: 'SowTypeTable',
  components: {
    SowTypeTableRow,
    DeletionConfirmationDialog,
    RegenerateConfirmationDialog,
  },
  props: {
    sow: {
      type: Object,
      default: () => ({}),
    },
    invested: {
      type: Boolean,
      default: false,
    },
    reportUnits: {
      type: Array,
      default: () => ([]),
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const firstLine = 'Are you sure you want to delete this summary unit?';
    const secondLine = 'All of the report units linked to it will be deleted as well';
    const deletionConfirmationText = `${firstLine}\n${secondLine}`;

    return {
      deletionConfirmationText,
      summaryReportUnitToDelete: {},
      isConfirmationDialogOpened: false,
      isRegenerateConfirmationDialogOpen: false,
      assignmentToRegenerate: {},
      employeeNameToRegenerate: {},
    };
  },
  computed: {
    ...mapGetters('auth/account', ['user']),
    ...mapGetters('user/settings', ['denseTables']),
    ...mapGetters('reports/main', ['report', 'isReportEditable']),
    ...mapGetters('reports/summary', ['summaryMap']),

    title() {
      return this.invested ? 'Invested' : 'Billable';
    },
    summaryData() {
      let summary = [];

      if (this.invested) {
        summary = this.summaryMap.get('invested');
      } else {
        const sowTypeID = sowTypeIDs[this.sow.sow_type] || sowTypeIDs[defaultType];
        const currentPoSummary = this.summaryMap.get(sowTypeID);

        if (summary) {
          summary = currentPoSummary.filter((assignment) => assignment.sow_po_number === this.sow.sow_po_number);
        }
      }

      return sortBy(summary, ['employee.name', 'summary_report_unit_id']);
    },
    currentSowTypeHeaders() {
      const headers = sowTypeHeaders[this.sow.sow_type] || sowTypeHeaders[defaultType];
      const actionsColumn = {
        value: 'actions',
        width: '100px',
        sortable: false,
      };

      return this.isReportEditable ? [...headers, actionsColumn] : headers;
    },
    total() {
      const defaultTotal = {
        employees: 0,
        expected_time: 0,
        non_working_time: 0,
        regular_time: 0,
        expected_irregular_time: 0,
        irregular_time: 0,
        total_hours: 0,
        approved_overtime: 0,
        ratio: 0,
      };

      if (!this.summaryData) return defaultTotal;

      /* eslint-disable no-param-reassign */
      const total = this.summaryData.reduce((total, summaryEntry) => {
        total.employees += 1;
        total.expected_time += summaryEntry.expected_time;
        total.non_working_time += summaryEntry.non_working_time;
        total.regular_time += summaryEntry.regular_time;
        total.expected_irregular_time += summaryEntry.expected_irregular_time;
        total.irregular_time += summaryEntry.irregular_time;
        total.total_hours += summaryEntry.total_hours;
        total.approved_overtime += summaryEntry.approved_overtime;
        total.ratio = +(total.ratio + summaryEntry.ratio).toFixed(2);

        return total;
      }, defaultTotal);
      /* eslint-enable */

      return total;
    },
  },
  methods: {
    ...mapActions('reports/summary', ['deleteSummaryReportUnit', 'getSummary']),
    ...mapActions('reports/units', ['getReportUnits']),

    openConfirmationDialog(summaryReportUnit) {
      this.summaryReportUnitToDelete = summaryReportUnit;

      this.isConfirmationDialogOpened = true;
    },
    showRegenerateConfirmationDialog(summaryReportUnit) {
      this.assignmentToRegenerate = summaryReportUnit.assignment_id;
      this.employeeNameToRegenerate = summaryReportUnit.employee.name;

      this.isRegenerateConfirmationDialogOpen = true;
    },
    deleteUnit(isConfirmed) {
      if (isConfirmed) {
        this.deleteSummaryReportUnit(this.summaryReportUnitToDelete);
      }

      this.isConfirmationDialogOpened = false;
    },
    async regenerateAssignment(isConfirmed) {
      if (isConfirmed) {
        const options = { loadingType: loadingTypes.none };

        await apiClient.reportUnitApi.regenerateAssignment(
          this.$route.params.id,
          this.assignmentToRegenerate,
          options,
        );

        this.getReportUnits({
          reportId: this.report.id,
          options,
        });
        this.getSummary({ reportId: this.report.id });
      }

      this.isRegenerateConfirmationDialogOpen = false;
    },
  },
};
</script>

<style lang="less">
@import "~variables";

.sow-type-table {
  overflow: auto;

  .sow-type-table__header {
    &-employee {
      min-width: 200px;
      width: 200px;
    }
    &-position {
      min-width: 110px;
      width: 1%;
    }
    &-billable_status {
      min-width: 150px;
      width: 150px;
    }
    &-month_time {
      min-width: 130px;
      width: 130px;
    }
    &-ratio {
      min-width: 120px;
      width: 120px;
    }
    &-holidays {
      min-width: 110px;
      width: 110px;
    }
    &-expected_time {
      min-width: 130px;
      width: 130px;
    }
    &-expected_irregular_time {
      min-width: 140px;
      width: 140px;
    }
    &-regular_time {
      min-width: 130px;
      width: 130px;
    }
    &-approved_overtime {
      min-width: 120px;
      width: 120px;
    }
    &-irregular_time {
      min-width: 140px;
      width: 140px;
    }
    &-total_hours {
      min-width: 120px;
      width: 120px;
    }
    &-non_working_time {
      min-width: 150px;
      width: 150px;
    }
  }

  .total-background {
    background-color: @grey-350;
  }
}
</style>
