<template>
  <tr
    v-if="!isEditing && item.id"
    :key="item.id"
    active-class="highlighted"
    :active="selected"
    :class="!item.isEven && 'even-day-row-highlight'"
    @click="editReportUnit"
  >
    <td
      class="index-column"
      @click.stop
    >
      <div class="add-button-wrapper">
        <v-btn
          v-if="isReportEditable && !isReportUnitEditing"
          color="primary"
          class="add-button"
          absolute
          icon
          small
          @click.prevent="addReportUnit"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <v-checkbox
          class="select-item-checkbox ml-2"
          color="primary"
          hide-details
          :disabled="!isReportEditable || isReportUnitEditing"
          :input-value="selected"
          @change="selectItem"
        />
      </div>
    </td>
    <td>{{ index + 1 }}</td>
    <td>{{ item.task_type_title }}</td>
    <td>{{ item.description }}</td>
    <v-tooltip left>
      <template #activator="{ on }">
        <td
          :class="getRegularHoursHighlight()"
          v-on="on"
        >
          {{ item.minutes | convertToDisplayTime }}
        </td>
      </template>
      <span>{{ tooltipHours() }}</span>
    </v-tooltip>
    <td :class="item.overtime_minutes && 'orange-highlight'">
      {{ item.overtime_minutes | convertToDisplayTime }}
    </td>
    <template v-if="!isDefaultReport">
      <td>{{ item.is_overtime_payable | readableBoolean }}</td>
      <td>{{ item.overtime_multiplier === null ? '0' : item.overtime_multiplier }}</td>
      <td>{{ item.is_billable | readableBoolean }}</td>
    </template>

    <td>{{ item.logged_date | convertToDisplayDate(dateFormatSettings) }}</td>

    <td class="action-column">
      <v-row justify="end">
        <v-btn
          text
          icon
          small
          :disabled="!isReportEditable || isReportUnitEditing"
          @click.prevent="duplicateReportUnit"
        >
          <v-icon class="material-icons-outlined">file_copy</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          small
          :disabled="!isReportEditable || isReportUnitEditing"
          @click.stop="deleteRow"
        >
          <v-icon>delete_outline</v-icon>
        </v-btn>
      </v-row>
    </td>
  </tr>

  <ReportUnitInput
    v-else
    :key="item.id"
    :report-unit-prototype="item"
    @save="completeCreation"
    @cancel="cancelEditingReportUnit"
  />
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';

import { DateHelper } from '@/helpers/date.helper';

import ReportUnitInput from './ReportUnitInput.vue';

export default {
  components: {
    ReportUnitInput,
  },

  filters: {
    readableBoolean(text) {
      return text ? 'YES' : 'NO';
    },
    convertToDisplayDate(date, dateFormat) {
      const isoDate = DateHelper.toIso(date);

      return DateHelper.formatDateWithDayOfWeekAccordingSettings(isoDate, dateFormat);
    },
    convertToDisplayTime(minutes) {
      return `${(minutes / 60).toFixed(2)}h`;
    },
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    isCurrentTab: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isEditing: false,
    };
  },

  computed: {
    ...mapGetters('reports/main', [
      'report',
      'isDefaultReport',
      'isReportEditable',
    ]),
    ...mapGetters('reports/units', [
      'isReportUnitEditing',
      'reportUnitsMap',
    ]),
    ...mapState('employees/single', ['employee']),
    ...mapGetters('user/settings', ['dateFormatSettings']),

    canEditReportUnit() {
      return !this.isReportUnitEditing
        && this.isReportEditable;
    },

    lastEditableReportUnit() {
      const editableReportUnits = this.reportUnitsMap[this.item.assignment_id];

      return editableReportUnits[editableReportUnits.length - 1];
    },
  },

  mounted() {
    addEventListener('keyup', this.arrowUpKeyHandler);
  },

  beforeDestroy() {
    removeEventListener('keyup', this.arrowUpKeyHandler);
  },

  methods: {
    ...mapActions('reports/units', ['cancelReportUnitEditing']),
    ...mapActions('reports/summary', ['getSummary']),
    ...mapMutations('reports/units', ['setReportUnitEditing']),

    editReportUnit() {
      if (!this.canEditReportUnit || event.defaultPrevented || window.getSelection().toString()) {
        return;
      }

      this.setEditing(true);
    },
    deleteRow() {
      this.$emit('onDelete', this.item);
    },
    duplicateReportUnit() {
      this.$emit('onDuplicate', this.item);
    },
    addReportUnit() {
      this.$emit('onAdd', this.item);
    },
    setEditing(value) {
      this.isEditing = value;
      this.setReportUnitEditing(value);
    },
    cancelEditingReportUnit() {
      this.cancelReportUnitEditing();
      this.setEditing(false);
    },
    completeCreation() {
      this.setEditing(false);
      this.getSummary({ reportId: this.report.id });
    },
    selectItem() {
      this.$emit('onSelect', this.item);
    },
    /** @param {KeyboardEvent} event */
    arrowUpKeyHandler(event) {
      if (this.canEditReportUnit
        && this.isCurrentTab
        && this.lastEditableReportUnit.id === this.item.id
        && (event.key === 'ArrowUp' || (event.ctrlKey && event.key === 'ArrowUp'))
      ) {
        this.setEditing(true);
      }
    },
    getRegularHoursHighlight() {
      const expectedTimePerDay = (this.item.employee.hour_per_week / 5) * 60;
      const loggedTimePerDay = this.getLoggedTimePerDay();
      const isOverLitimTime = this.getLoggedOverLimitTime();

      switch (true) {
        case (this.highlight && !!isOverLitimTime):
          return 'blue lighten-4';
        case (loggedTimePerDay < expectedTimePerDay):
          return 'red lighten-4';
        case (loggedTimePerDay > expectedTimePerDay):
          return 'amber lighten-4';
        default: // equal
          return 'green lighten-3';
      }
    },
    tooltipHours() {
      return `${this.getLoggedTimePerDay() / 60}/${this.item.employee.hour_per_week / 5}`;
    },
    getLoggedTimePerDay() {
      return this.reportUnitsMap[this.item.assignment_id]
        .filter((unit) => unit.id && unit.logged_date === this.item.logged_date)
        // eslint-disable-next-line no-param-reassign
        .reduce((timeCounter, unit) => timeCounter += unit.minutes, 0);
    },
    getLoggedOverLimitTime() {
      return this.reportUnitsMap[this.item.assignment_id]
        .find((unit) => unit.id && unit.id === this.item.id && unit.over_limit === true);
    },
  },
};
</script>

<style lang="less">
@import "~variables";
.report-unit-employee-table {
  .add-button-wrapper {
    position: relative;
    width: 24px;
    height: 100%;
  }

  tr:not(:hover) .add-button {
    display: none;
  }

  .action-column {
    .row {
      margin: 0;

      .v-btn {
        color: #555;
        margin: 1px;
        height: inherit;
        width: inherit;

        &:hover {
          background-color: rgba(0,0,0,.1);
          transition: all 0.5s ease;
        }
      }
    }
  }

  .cell-content {
    display: inline-block;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }

// Description
  tr td:nth-child(7) {  word-break: break-all; }

  .even-day-row-highlight {
    background-color: @grey; // blue-grey lighten-4

    &:hover {
      background-color: @grey-light; // blue-grey lighten-5
    }
  }

  tr[active]:not(.row-editor) {
    background-color: @orange-12pct;

    &:hover {
      background-color: @grey-light;
    }
  }

  .orange-highlight {
    background-color: #fbddd2;
  }

  .add-button {
    min-width: 24px;
    width: 24px;
    height: 24px;
    bottom: -16px;
    left: -16px;
    z-index: 1;
    overflow: hidden;
    transition: background-color 0.5s ease;
    i {
      transition: transform 0.5s ease, color 0.5s ease;
    }
    &:hover {
      background-color: #f26a399f;
      i {
        color: white;
        transform: rotate(360deg);
      }
    }
  }
}
</style>
