var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.clickOutsideHandler),expression:"clickOutsideHandler"}],staticClass:"row-editor",attrs:{"id":"currentlyEditingReportUnit","active":""}},[_c('td'),_c('td'),_c('td',[_c('v-select',{ref:"reportUnit_title",attrs:{"items":_vm.taskTypes,"item-text":"title","item-value":"id","label":"Task Type","persistent-hint":"","autofocus":!_vm.reportUnit.task_type_id,"rules":[
        () => !!_vm.reportUnit.task_type_id || 'Required',
      ]},model:{value:(_vm.reportUnit.task_type_id),callback:function ($$v) {_vm.$set(_vm.reportUnit, "task_type_id", $$v)},expression:"reportUnit.task_type_id"}})],1),_c('td',[_c('v-textarea',{ref:"reportUnit_description",staticClass:"text-field",attrs:{"label":"Description","rows":"1","autofocus":!!_vm.reportUnit.task_type_id,"auto-grow":"","rules":[
        () => !!_vm.reportUnit.description.trim() || 'Required',
      ]},model:{value:(_vm.reportUnit.description),callback:function ($$v) {_vm.$set(_vm.reportUnit, "description", $$v)},expression:"reportUnit.description"}})],1),_c('td',[_c('v-combobox',{ref:"reportUnit_time",attrs:{"label":"Time","placeholder":"0.00","rules":[
        _vm.isValidTimeAndOvertime,
        () => !isNaN(_vm.reportUnit.time) || 'Should be a number',
        () => (_vm.reportUnit.time >= 0) || 'Should be a not negative number',
      ],"items":_vm.timeOptions},on:{"blur":_vm.roundToNearestQuarter},model:{value:(_vm.reportUnit.time),callback:function ($$v) {_vm.$set(_vm.reportUnit, "time", $$v)},expression:"reportUnit.time"}})],1),_c('td',[_c('v-combobox',{ref:"reportUnit_overtime",attrs:{"label":"Overtime","placeholder":"0.00","rules":[
        _vm.isValidTimeAndOvertime,
        () => !isNaN(_vm.reportUnit.overtime_time) || 'Should be a number',
        () => (_vm.reportUnit.overtime_time >= 0) || 'Should be a not negative number',
      ],"items":_vm.timeOptions},on:{"blur":_vm.roundToNearestQuarter},model:{value:(_vm.reportUnit.overtime_time),callback:function ($$v) {_vm.$set(_vm.reportUnit, "overtime_time", $$v)},expression:"reportUnit.overtime_time"}})],1),(!_vm.isDefaultReport)?[_c('td',[_c('v-checkbox',{staticClass:"checkbox-alignment",attrs:{"color":"primary","disabled":""},model:{value:(_vm.reportUnit.is_overtime_payable),callback:function ($$v) {_vm.$set(_vm.reportUnit, "is_overtime_payable", $$v)},expression:"reportUnit.is_overtime_payable"}})],1),_c('td',[_c('v-text-field',{attrs:{"color":"primary","disabled":""},model:{value:(_vm.reportUnit.overtime_multiplier),callback:function ($$v) {_vm.$set(_vm.reportUnit, "overtime_multiplier", $$v)},expression:"reportUnit.overtime_multiplier"}})],1),_c('td',[_c('v-checkbox',{staticClass:"checkbox-alignment",attrs:{"color":"primary","disabled":""},model:{value:(_vm.reportUnit.is_billable),callback:function ($$v) {_vm.$set(_vm.reportUnit, "is_billable", $$v)},expression:"reportUnit.is_billable"}})],1)]:_vm._e(),_c('td',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({ref:"reportUnit_date",attrs:{"label":"Date","append-icon":"event","rules":[
            () => !!_vm.reportUnit.logged_date || 'Required',
            _vm.dateIsValid,
            _vm.isDateInReportDateRange,
          ]},on:{"click:append":function($event){_vm.datePickerVisible = true},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.datePickerVisible = true}},model:{value:(_vm.reportUnit.logged_date),callback:function ($$v) {_vm.$set(_vm.reportUnit, "logged_date", $$v)},expression:"reportUnit.logged_date"}},on))]}}]),model:{value:(_vm.datePickerVisible),callback:function ($$v) {_vm.datePickerVisible=$$v},expression:"datePickerVisible"}},[_c('v-date-picker',{ref:"datePicker",attrs:{"value":_vm.datePickerValue,"first-day-of-week":_vm.firstDayOfWeek,"color":"primary","no-title":""},on:{"input":_vm.saveDateInUTC}})],1)],1),_c('td',{staticClass:"action-column"},[_c('v-row',{staticClass:"pt-4",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"text":"","icon":"","small":""},on:{"click":_vm.saveUnit}},[_c('v-icon',[_vm._v("done")])],1),_c('v-btn',{attrs:{"text":"","icon":"","small":""},on:{"click":_vm.cancelEdit}},[_c('v-icon',[_vm._v("clear")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }