var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.updateSummaryReportUnit),expression:"updateSummaryReportUnit"}],staticClass:"summary-report-unit-input"},[_vm._l((_vm.headers),function(column,index){return [(!_vm.editableColumns.includes(column.value))?_c('td',{key:index},[_c('div',{class:{
          'pt-6': true,
          'text-nowrap': column.value !== 'employee.name',
        }},[_vm._v(" "+_vm._s(_vm.getByPath(_vm.summaryReportUnitPrototype, column.value))+" ")])]):_vm._e(),(column.value === 'expected_time')?_c('td',{key:index},[_c('v-text-field',{ref:"summaryReportUnit_expected_time",refInFor:true,attrs:{"placeholder":_vm.summaryReportUnitPrototype.expected_time.toString(),"rules":[
          () => Boolean(_vm.summaryReportUnit.expected_time) || _vm.summaryReportUnit.expected_time === 0 || 'Required',
          () => !isNaN(_vm.summaryReportUnit.expected_time) || 'Should be a number',
          () => (_vm.summaryReportUnit.expected_time >= 0) || 'Should be not negative number',
        ]},model:{value:(_vm.summaryReportUnit.expected_time),callback:function ($$v) {_vm.$set(_vm.summaryReportUnit, "expected_time", $$v)},expression:"summaryReportUnit.expected_time"}})],1):_vm._e(),(column.value === 'expected_irregular_time')?_c('td',{key:index},[_c('v-text-field',{ref:"summaryReportUnit_expected_time",refInFor:true,attrs:{"placeholder":_vm.summaryReportUnitPrototype.expected_irregular_time.toString(),"rules":[
          () => Boolean(_vm.summaryReportUnit.expected_irregular_time)
            || _vm.summaryReportUnit.expected_irregular_time === 0
            || 'Required',
          () => !isNaN(_vm.summaryReportUnit.expected_irregular_time) || 'Should be a number',
          () => (_vm.summaryReportUnit.expected_irregular_time >= 0) || 'Should be not negative number',
        ]},model:{value:(_vm.summaryReportUnit.expected_irregular_time),callback:function ($$v) {_vm.$set(_vm.summaryReportUnit, "expected_irregular_time", $$v)},expression:"summaryReportUnit.expected_irregular_time"}})],1):_vm._e(),(column.value === 'month_time')?_c('td',{key:index},[_c('v-text-field',{ref:"summaryReportUnit_expected_time",refInFor:true,attrs:{"placeholder":_vm.summaryReportUnitPrototype.month_time.toString(),"rules":[
          () => Boolean(_vm.summaryReportUnit.month_time) || _vm.summaryReportUnit.month_time === 0 || 'Required',
          () => !isNaN(_vm.summaryReportUnit.month_time) || 'Should be a number',
          () => (_vm.summaryReportUnit.month_time >= 0) || 'Should be not negative number',
        ]},model:{value:(_vm.summaryReportUnit.month_time),callback:function ($$v) {_vm.$set(_vm.summaryReportUnit, "month_time", $$v)},expression:"summaryReportUnit.month_time"}})],1):_vm._e(),(column.value === 'non_working_time')?_c('td',{key:index},[_c('v-text-field',{ref:"summaryReportUnit_non_working_time",refInFor:true,attrs:{"placeholder":_vm.summaryReportUnitPrototype.non_working_time.toString(),"rules":[
          () => Boolean(_vm.summaryReportUnit.non_working_time) || _vm.summaryReportUnit.non_working_time === 0 || 'Required',
          () => !isNaN(_vm.summaryReportUnit.non_working_time) || 'Should be a number',
          () => (_vm.summaryReportUnit.non_working_time >= 0) || 'Should be not negative number',
        ]},model:{value:(_vm.summaryReportUnit.non_working_time),callback:function ($$v) {_vm.$set(_vm.summaryReportUnit, "non_working_time", $$v)},expression:"summaryReportUnit.non_working_time"}})],1):_vm._e(),(column.value === 'comment')?_c('td',{key:index},[_c('v-textarea',{ref:"summaryReportUnit_comment",refInFor:true,staticClass:"text-field",attrs:{"rows":"1","auto-grow":"","autofocus":Boolean(_vm.summaryReportUnit.expected_time) && Boolean(_vm.summaryReportUnit.non_working_time),"rules":[]},model:{value:(_vm.summaryReportUnit.comment),callback:function ($$v) {_vm.$set(_vm.summaryReportUnit, "comment", $$v)},expression:"summaryReportUnit.comment"}})],1):_vm._e(),(column.value === 'actions')?_c('td',{key:index,staticClass:"actions-column"},[_c('v-row',{staticClass:"pt-6",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"text":"","icon":"","small":""},on:{"click":_vm.updateSummaryReportUnit}},[_c('v-icon',[_vm._v("done")])],1),_c('v-btn',{attrs:{"text":"","icon":"","small":""},on:{"click":_vm.cancelEdit}},[_c('v-icon',[_vm._v("clear")])],1)],1)],1):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }